import React, { useState, useCallback, Fragment, useEffect, useRef } from "react";
import { doc, getDoc, Timestamp } from "firebase/firestore";
import { getDatabase, ref as databaseRef, set, get, query, orderByChild } from "firebase/database";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db } from "../../firebaseConfig.js";

import { useDrop } from 'react-dnd'
import { NativeTypes } from 'react-dnd-html5-backend'
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    FormGroup,
    Label,
    Input
} from "reactstrap";
import TextareaAutosize from 'react-textarea-autosize';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; 
import {
    faFileArrowUp,
    faFolderOpen,
    faUpload,
    faCircleCheck
} from "@fortawesome/free-solid-svg-icons";

import "../../components/FileTransfer/FileTransfer.css";
import { orderBy } from "lodash-es";

const realtimeDB = getDatabase();
const storage = getStorage();

const setFileIcon = (extension) => {
    switch (extension) {
        case "pdf":
            return "fa-file-pdf";
        case "xlsx":
            return "fa-file-excel";
        case "csv":
            return "fa-file-csv";
        case "pptx":
            return "fa-file-powerpoint";
        case "jpeg":
        case "jpg":
        case "png":
            return "fa-file-image";
        default:
            return "fa-file";
    };
}

const setValidFileName = (fileName) => {
    // only allow valid DOM characters
    return fileName.replace(/^[^a-z]+|[^\w:.-]+/gi, "_");
};

const setFileNameWithPeriod = (fileName) => {
    const fileNameArray = fileName.split(".")
    
    if (fileNameArray.length === 2) {
        return fileNameArray[0];
    } else {
        let name = "";

        for (const value of fileNameArray.slice(0, -1)) {
            name = name + value;
        };

        return name;
    };
};

const acceptedFileArray = ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/pdf",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "text/plain",
    "image/png",
    "image/jpeg",
    "image/svg+xml",
    "image/bmp"];

const JupiterFileUpload = (props) => {
    const { access, dbType, folderPath, filePath } = props;

    const [droppedFiles, setDroppedFiles] = useState([])
    const [fileComplete, setFileComplete] = useState(false);

    const [modal, setModal] = useState(false);

    const ref = useRef();

    var fileDirSelected = false;

    const toggle = (e) => {
        let btnID = e.currentTarget.id;

        if (btnID === "btnUploadComplete") {
            setModal(!modal);
            setDroppedFiles([]);
            setFileComplete(false);
        } else if (btnID === "btnUpload") {
            setModal(!modal);
            setFileComplete(false);
            setDroppedFiles([]);
        } else if (btnID === "btnCancel" && droppedFiles.length > 0) {
            setDroppedFiles([]);
        } else {
            setModal(!modal);
        };
    };
    
    const handleFileDrop = useCallback((item) => {
        if (item) {
            const files = item.files
            setDroppedFiles(files)
        };
    }, [setDroppedFiles]);

    const openFileDirectory = (e) => {
        ref.current.click();
    };

    const onFileSelected = (e) => {
        e.stopPropagation();
        e.preventDefault();

        let file = e.target.files[0];

        setDroppedFiles([file]);
    };

    return (
        <Fragment>
            {access > 2 ?
                <Button id="btnUpload" color="success" style={{ marginTop: "13px", marginRight: "10px", maxHeight: "45px", minHeight: "45px"}} onClick={toggle}>
                    <FontAwesomeIcon size="2xl" icon={faFileArrowUp} className="mr-4" />
                </Button>
                : null
            }
            <Modal isOpen={modal} toggle={toggle} backdrop="static">
                <ModalHeader >
                    <strong>Upload File</strong>
                </ModalHeader>
                <ModalBody>
                    {fileComplete ? 
                        <div className="upload-complete">
                            File Uploaded Succesfully
                        </div>
                        :
                        <Fragment>
                            {droppedFiles.length > 0 || fileDirSelected ?
                                <UploadFile file={droppedFiles} fileComplete={setFileComplete} dbType={dbType} folderPath={folderPath} filePath={filePath} />
                                :
                                <div className="file-container">
                                    <TargetBox onDrop={handleFileDrop} />
                                </div>
                            }
                        </Fragment>
                    }
                </ModalBody>
                <ModalFooter style={{ display: "flex", justifyContent: "space-evenly" }}>
                    {droppedFiles.length === 0 && !fileComplete ?
                        <Fragment>
                            <Button color="primary" onClick={openFileDirectory}>
                                <FontAwesomeIcon icon={faFolderOpen} className="mr-2" />
                                File Directory
                            </Button>
                            <input ref={ref} type="file" style={{ display: "none" }} onChange={onFileSelected} />
                        </Fragment>
                        : null
                        
                    }
                    {fileComplete ?
                        <Button id="btnUploadComplete" color="success" onClick={toggle}>
                            <FontAwesomeIcon icon={faCircleCheck} size={"sm"} />
                        </Button>
                        :
                        <Button id="btnCancel" color="danger" onClick={toggle}>
                            Cancel
                        </Button>
                    }
                </ModalFooter>
            </Modal>
        </Fragment>

    );
}

export default JupiterFileUpload;

const TargetBox = (props) => {
    const { onDrop } = props
    const [fileCollected, setFileCollected] = useState(false);

    const [{ canDrop, isOver }, drop] = useDrop(() => ({
        accept: [NativeTypes.FILE],
        drop(item) {
            if (onDrop) {
                onDrop(item);
            };
        },
        canDrop(item) {
            // set what files are accepted
            //console.log("canDrop", item.files, item.items);
            if (item.files.length > 0 && item.files[0].type !== undefined) {
                if (acceptedFileArray.includes(item.files[0].type)) {
                    return true;
                }
            } else if (item.files.length > 0 && !acceptedFileArray.includes(item.files[0].type)) {
                return false;
            }
            
        },
        hover(item) {
            // when hovering over item
            //console.log('hover', item.files, item.items)
        },
        collect: (monitor) => {
            const item = monitor.getItem()
            if (item) {
                //console.log('collect', item.files, item.items)
            }
            return {
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop(),
            }
        },
    }),
        [props],
    )

    // only activate div (with ref={drop} which signals div is dnd enabled) when
    // these two conditions are met
    const isActive = canDrop && isOver;

    return (
        // style this box
        <div ref={drop}>
            {fileCollected ?
                <Fragment>
                    
                </Fragment>
                :
                <Fragment>
                    {isActive ?
                        <div className="upload-dropbox-file-over">
                            <div className="upload-dropbox-text">
                                Drop file
                            </div>
                        </div>
                        :
                        <div className="upload-dropbox">
                            <div className="upload-dropbox-text">
                                Drag file here
                            </div>
                        </div>
                    }  
                </Fragment>
            }
        </div>
    );
}

const PrepUpload = (props) => {
    const [uploadOptions, setUploadOptions] = useState();

    var userData = JSON.parse(window.sessionStorage.userData);

    useEffect(() => {
        const getUploadOptions = async (customer) => {    // <--- modify this function to add list of options
            const docRef = doc(db, "User_Venues", customer);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                setUploadOptions(docSnap.data());
            } else {
                console.log(">>> NO SUCH DOC");
            }
        };

        //getUploadOptions(userData.companyName); <--- 
    }, []);

    return (
        <Fragment>
            {uploadOptions || true ?
                <UploadFile {...props} /*uploadOptions={uploadOptions}*/ />
                :
                null
            }
        </Fragment>
    )
}

const UploadFile = (props) => {
    const { file, fileComplete, dbType, folderPath, filePath } = props;

    const [fileNameInput, setFileNameInput] = useState(file[0].name.split(".").slice(0, -1).join(""));
    console.log("File Name: " + file[0].name.split(".").slice(0, -1).join("."))
    const [fileDescription, setFileDescription] = useState("");
    const [validateUpload, setValidateUpload] = useState(false);

    let dbPath = dbType === 0 ? 2 : 1;
    let storageFolderPath = folderPath.split("/").splice(dbPath).join().replaceAll(/,/g, "/");

    const uploadFile = () => {
        const userData = JSON.parse(window.sessionStorage.userData);
        
        const storageRef = ref(storage, filePath + storageFolderPath + "/" + fileNameInput);

        const fileNameArray = file[0].name.split(".");
        const extension = fileNameArray[fileNameArray.length - 1];

        // upload file
        uploadBytes(storageRef, file[0])
            .then((snapshot) => {
                return getDownloadURL(snapshot.ref);
            })
            .then((downloadURL) => {
                // once we have the download URL upload the date to firestore
                const fileData = {
                    fileName: fileNameInput,
                    fileNameValidDOM: setValidFileName(fileNameInput),
                    fileExtension: extension,
                    fileType: file[0].type,
                    fileSize: (file[0].size / (1024 * 1024)).toFixed(2).toString() + "MB",
                    fileDescription: fileDescription,
                    fileIcon: setFileIcon(extension),
                    downloadURL: downloadURL,
                    uploadedBy: userData.employeeID,
                    uploadedDate: Timestamp.fromDate(new Date())
                };

                // upload to rtdb
                // jupiter files
                set(databaseRef(realtimeDB, filePath + storageFolderPath + "/" + fileNameInput), fileData)
                    .then(() => {
                        // jupiter recent files
                        if (dbType > 0) {
                            let recentFilesRef = databaseRef(realtimeDB, "jupiter_recent_files")
                            const q = query(recentFilesRef, orderByChild("UploadedDate"));

                            get(q)
                                .then((res) => {
                                    if (res.val() != null && Object.keys(res.val()).length === 10) {
                                        for (const file of res) {
                                            console.log(file.val());
                                            // delete last object
                                        }
                                        res[0].remove();
                                        set(recentFilesRef, { dbType: dbType, ...fileData })
                                            .then(() => {

                                            }).catch((error) => {
                                                console.log(error)
                                            });

                                    } else {
                                        set(databaseRef(realtimeDB, "jupiter_recent_files/" + fileNameInput), { dbType: dbType, ...fileData })
                                            .then(() => {

                                            }).catch((error) => {
                                                console.log(error)
                                            });
                                    }
                                })
                        }
                    }).catch((error) => {
                        console.log(error);
                    });

                fileComplete(true);
            });
    };

    const handleFileName = (e) => {
        let fileName = e.target.value.replace(/[[/.\],#$]/gi, "");

        setFileNameInput(fileName);

        if (fileNameInput.length < 5) {
            setValidateUpload(true);
        } else {
            setValidateUpload(false);
        };
    };

    const handleFileDescription = (e) => {
        setFileDescription(e.target.value);
    };

    return (
        <div>
            <div style={{ textAlign: "center", marginBottom: "15px" }}>
                <strong>Raw File Name:</strong> {file[0].name.split(".").slice(0, -1).join(".")}
            </div>
            <Form>
                <FormGroup>
                    <Label for="fileName" style={{ fontWeight: "bold" }}>
                        File Name
                    </Label>
                    <Input id="fileName" name="fileName" type="text" value={fileNameInput} onChange={(e) => handleFileName(e)} />
                </FormGroup>

                <FormGroup>
                    <Label for="fileDescription" style={{ fontWeight: "bold" }}>
                        File Description
                    </Label>
                    <TextareaAutosize name="fileDescription" style={{ width: "100%", border: "1px solid lightgray", borderRadius: "5px", fontSize: "14px" }} onChange={handleFileDescription} spellCheck="true" autoComplete="off" minRows="3" />
                </FormGroup>

                <div className="upload-btn">
                    <Button color="success" onClick={uploadFile} className="upload-btn" disabled={validateUpload}>
                        <FontAwesomeIcon icon={faUpload} className="mr-2" />
                        Upload
                    </Button>
                </div>
            </Form>
        </div>
    )
};