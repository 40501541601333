import React, { Fragment, useState, useEffect, useRef } from "react";
import { useReactToPrint } from 'react-to-print';
import { db } from "../../firebaseConfig";
import { collection, query, where, doc, getDocs, getDoc, updateDoc, addDoc, orderBy, Timestamp } from "firebase/firestore";
import moment from "moment"
import {
    Button,
    Label,
    ListGroup,
    ListGroupItem,
    Table,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCircleCheck,
    faHourglassHalf,
    faSquareCheck
} from "@fortawesome/free-solid-svg-icons";
import TextareaAutosize from 'react-textarea-autosize';

import logo from "../../assets/aw-logo.png";

const generateWeek = () => {
    let week = [null, null, null, null, null, null, null];

    return week;
}

const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

const todaysDate = moment().format("MM/DD/YYYY");

const getTimeSheetTemplate = () => {
    const newDate = new Date();

    let daysInMonth = moment(newDate.getFullYear() + "-" + ('0' + (newDate.getMonth() + 1)).slice(-2), "YYYY-MM").daysInMonth();
    let totalDays = daysInMonth;
    let arrayDays = [];
    let arrayWeeks = [];
    let week = generateWeek();
    let dayIndex = 0;

    // generate array of Date objects
    while (daysInMonth) {
        let current = moment().date(daysInMonth);

        arrayDays.push(current);
        daysInMonth--;
    }

    arrayDays = arrayDays.reverse()

    // populate Month object
    arrayDays.forEach((day, i) => {
        dayIndex = day.day();

        week[dayIndex] = [i + 1, dayNames[dayIndex], day.format("MM/DD/YYYY"), 0, ""];

        if (dayIndex === 6 || (i + 1) === totalDays) {
            arrayWeeks.push(week);
            week = generateWeek();
        }
    })

    return arrayWeeks;
}

const getTimeSheetTotalHours = (timeSheet) => {
    let totalHours = 0;

    for (let week of timeSheet) {
        for (let day of week) {
            totalHours += day != null && day[3] !== "" ? parseInt(day[3]) : 0;
        }
    }

    return totalHours;
}

const VenusTimeSheet = (props) => {
    const { userID, userName, access, admin } = props;

    const [timeSheetList, setTimeSheetList] = useState([]);
    const [createTimeSheetButton, setCreateTimeSheetButton] = useState(true);
    const [timeSheetListAllUsers, setTimeSheetListAllUsers] = useState({});
    const [updateTimeSheet, setUpdateTimeSheet] = useState(false);
    const [timeSheetData, setTimeSheetData] = useState({});
    const [timeSheetTotalHours, setTimeSheetTotalHours] = useState(0);
    const [timeSheetApproved, setTimeSheetApproved] = useState(false);
    const [printingTimeSheet, setPrintingTimeSheet] = useState(false);

    const [timeSheetModal, setTimeSheetModal] = useState(false);
    const [nestedModal, setNestedModal] = useState(false);

    const componentRef = useRef(null);
    const promiseResolveRef = useRef(null);

    const toggleTimeSheet = () => setTimeSheetModal(!timeSheetModal);
    const toggleNested = () => setNestedModal(!nestedModal);

    var timeSheetUserType = admin ? "Employee Time Sheet" : "Your Time Sheets";

    const getTimeSheetList = async () => {
        let newMoment = moment();
        let timeSheetList = [];

        const q = query(collection(db, "Venus_TimeSheets"), where("employeeID", "==", userID), orderBy("timeSheetDate", "desc"));
        const docSnap = await getDocs(q);

        docSnap.forEach((timeSheet) => {
            timeSheetList.push({ id: timeSheet.id, ...timeSheet.data() });

            if (timeSheet.data().timeSheetDate.replace(" ", "/") === (monthNames[newMoment.month()] + "/" + newMoment.year())) {
                setCreateTimeSheetButton(false);
            }
        });

        if (timeSheetList.length > 0) {
            setTimeSheetList(timeSheetList);
        }
    };

    const getTimeSheetListAllUsers = async () => {
        let timeSheetListAll = {};
        let userFullName = "";
        let previousUserID = -1;
        let currentUserID = -1;

        const qAllTimeSheets = query(collection(db, "Venus_TimeSheets"), orderBy("employeeID"), orderBy("timeSheetDate", "desc"));
        const docSnap = await getDocs(qAllTimeSheets);

        // structure: object -> array -> objects
        for (const timeSheet of docSnap.docs) {
            currentUserID = timeSheet.data().employeeID;

            if (currentUserID === userID) {
                continue;
            } else if (currentUserID === previousUserID) {
                // insert data
                timeSheetListAll[userFullName].push({ id: timeSheet.id, ...timeSheet.data() });

                currentUserID = timeSheet.data().employeeID;
            } else {
                const qUserInfo = query(collection(db, "Users"), where("employeeID", "==", timeSheet.data().employeeID));
                const docSnapUser = await getDocs(qUserInfo);

                for (const user of docSnapUser.docs) {
                    userFullName = user.data().firstName + "_" + user.data().lastName;
                }

                // initialize Object array
                timeSheetListAll[userFullName] = [];
                timeSheetListAll[userFullName].push({ id: timeSheet.id, userName: userFullName.replace("_", " "), ...timeSheet.data() });

                previousUserID = timeSheet.data().employeeID;
            }
        }

        if (Object.keys(timeSheetListAll).length > 0) {
            setTimeSheetListAllUsers({ ...timeSheetListAll });
        };
    };

    const handleCreateTimeSheet = (e) => {
        setUpdateTimeSheet(false);

        const newDate = new Date();

        // timesheet object
        const blankTimeSheet = {
            timeSheetUserName: userName,
            timeSheetDate: monthNames[newDate.getMonth()] + " " + newDate.getFullYear(),
            timeSheetDays: getTimeSheetTemplate(),
            timeSheetTotalHours: 0,
            timeSheetApproved: false
        }

        setTimeSheetData({ ...blankTimeSheet });
    };

    const getTimeSheetData = async (timeSheetID) => {
        setUpdateTimeSheet(true);

        const docRef = doc(db, "Venus_TimeSheets", timeSheetID);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            let data = { id: docSnap.id, ...docSnap.data() };

            data.timeSheetDays = JSON.parse(data.timeSheetDays);

            setTimeSheetTotalHours(data.timeSheetTotalHours);
            setTimeSheetApproved(data.timeSheetApproved);
            setTimeSheetData({ ...data });
        };
    };

    const handleHourData = (e, weekIndex, dayIndex) => {
        if (e.target.value === "") {
            timeSheetData.timeSheetDays[weekIndex][dayIndex][3] = 0;
        }

        timeSheetData.timeSheetDays[weekIndex][dayIndex][3] = e.target.value;
        
        setTimeSheetData({ ...timeSheetData });
    };

    const handleTimeSheetDataNotes = (e, weekIndex, dayIndex) => {
        timeSheetData.timeSheetDays[weekIndex][dayIndex][4] = e.target.value;

        setTimeSheetData({ ...timeSheetData });
    };

    const handleSaveChanges = async (e) => {
        let totalTimeSheetHours = getTimeSheetTotalHours(timeSheetData.timeSheetDays);

        if (updateTimeSheet) {
            // use timesheet auto-id to update doc
            const docRef = doc(db, "Venus_TimeSheets", timeSheetData.id);

            // convert Date to Timestamp - Timestamp.fromDate(new Date())

            await updateDoc(docRef, {
                timeSheetDays: JSON.stringify(timeSheetData.timeSheetDays),
                timeSheetTotalHours: totalTimeSheetHours,
                timeSheetApproved: timeSheetApproved
            });

        } else {
            // create doc and save info
            await addDoc(collection(db, "Venus_TimeSheets"), {
                employeeID: userID,
                timeSheetUserName: timeSheetData.timeSheetUserName,
                timeSheetDate: timeSheetData.timeSheetDate,
                timeSheetDays: JSON.stringify(timeSheetData.timeSheetDays),
                timeSheetTotalHours: totalTimeSheetHours,
                timeSheetApproved: timeSheetApproved
            }).then((docRef) => {
                timeSheetData.id = docRef.id;
            }).catch((error) => {
                console.log(error);
            });

            setUpdateTimeSheet(true);
        }

        if (admin) {
            getTimeSheetListAllUsers();
        } else {
            getTimeSheetList();
        }
        
        setTimeSheetTotalHours(totalTimeSheetHours);
        toggleNested();
    };

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: Object.keys(timeSheetData).length > 0 ? timeSheetData.timeSheetUserName.replace(" ", "_") + "_" + timeSheetData.timeSheetDate.replace(" ", "_") + "_TimeSheet" : "",
        onBeforeGetContent: () => {
            return new Promise((resolve) => {
                promiseResolveRef.current = resolve;
                setPrintingTimeSheet(true);
            });
        },
        onAfterPrint: () => {
            promiseResolveRef.current = null;
            setPrintingTimeSheet(false);
        }
    });

    // get users lists of timesheet
    useEffect(() => {
        if (admin) {
            getTimeSheetListAllUsers()
        } else {
            getTimeSheetList();
        }
    }, []);

    // set printing state
    useEffect(() => {
        if (printingTimeSheet && promiseResolveRef.current) {
            // Resolves the Promise, letting `react-to-print` know that the DOM updates are completed
            promiseResolveRef.current();
        }
    }, [printingTimeSheet]);

    return (
        <Fragment>
            <div>
                <div className="venus-app-header">
                    <div style={{ fontSize: "26px", fontWeight: "bold", marginTop: "0px" }}>{timeSheetUserType}</div>
                    {!admin && createTimeSheetButton ?
                        <Fragment>
                            <Button color="primary" style={{ fontWeight: "bold" }} onClick={() => { toggleTimeSheet(); handleCreateTimeSheet() }}>+ Create New Time Sheet</Button>
                        </Fragment>
                        : null
                    }
                </div>
                {admin && access === 4 ?
                    <Fragment>
                        {Object.keys(timeSheetListAllUsers).length > 0 ?
                            <Fragment>
                                {Object.keys(timeSheetListAllUsers).map((timeSheets) => (
                                    <div key={timeSheets}>
                                        <Label className="venus-quote-list-user">{timeSheets.replace("_", " ")}</Label>
                                        <ListGroup style={{ margin: "0px 20px 0px 20px" }}>
                                            {timeSheetListAllUsers[timeSheets].map((timeSheet) => (
                                                <ListGroupItem className="venus-quote-list-item" key={timeSheet.id} action tag="button" onClick={() => { toggleTimeSheet(); getTimeSheetData(timeSheet.id, timeSheet.userName) }}>
                                                    <div>
                                                        {timeSheet.timeSheetDate}
                                                    </div>
                                                    <div style={{ display: "flex" }}>
                                                        {timeSheet.timeSheetApproved === true ?
                                                            <div className="venus-timesheet-approved-list">
                                                                <FontAwesomeIcon icon={faSquareCheck} />
                                                            </div>
                                                            : null
                                                        }
                                                        Total Hours: {timeSheet.timeSheetTotalHours}
                                                    </div>
                                                </ListGroupItem>
                                            ))}
                                        </ListGroup>
                                    </div>
                                ))}
                            </Fragment>
                            :
                            <div className="venus-quote-no-quotes">
                                No Time Sheets to display
                            </div>
                        }
                    </Fragment>
                    :
                    <Fragment>
                        {timeSheetList.length > 0 ?
                            <div>
                                <ListGroup style={{ margin: "0px 20px 0px 20px" }}>
                                    {timeSheetList.map((timeSheet) => (
                                        <ListGroupItem className="venus-quote-list-item" key={timeSheet.id} action tag="button" onClick={() => { toggleTimeSheet(); getTimeSheetData(timeSheet.id, null) }}>
                                            <div>
                                                {timeSheet.timeSheetDate}
                                            </div>
                                            <div style={{ display: "flex" }}>
                                                {timeSheet.timeSheetApproved === true ?
                                                    <div className="venus-timesheet-approved-list">
                                                        <FontAwesomeIcon icon={faSquareCheck} />
                                                    </div>
                                                    : null
                                                }
                                                Total Hours: {timeSheet.timeSheetTotalHours}
                                            </div>
                                        </ListGroupItem>
                                    ))}
                                </ListGroup>
                            </div>
                            :
                            <div className="venus-quote-no-quotes">
                                No Time Sheets to display
                            </div>
                        }
                    </Fragment>
                }
            </div>

            <Modal isOpen={timeSheetModal} toggle={toggleTimeSheet} size="xl" className="venus-timesheet-modal" backdrop="static">
                {/*<ModalHeader toggle={toggleTimeSheet}>*/}
                {/*    <div>*/}

                {/*    </div>*/}
                {/*</ModalHeader>*/}

                <ModalBody style={{ padding: "0px 0px 0px 0px !important" }}>
                    <div id="venusTimeSheet" ref={componentRef}>
                        <div className="venus-quote-modal-header">
                            <div style={{ display: "flex" }}>
                                <img alt="logo" src={logo} style={{ width: 75, height: 75 }} />
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <p style={{ fontWeight: "bold", fontSize: "38px", marginTop: "0px", marginBottom: "-12px" }}>Time Sheet {"timeSheetDate" in timeSheetData ? timeSheetData.timeSheetDate : null}</p>
                                    <p style={{ fontWeight: "bold", fontSize: "22px", marginTop: "0px" }}>{timeSheetData.timeSheetUserName}</p>
                                </div>
                            </div>
                            <div>
                                {timeSheetApproved === true ?
                                    <div className="venus-timesheet-approved">
                                        <FontAwesomeIcon icon={faSquareCheck} size="2xl" />
                                    </div>
                                    : null}
                            </div>
                        </div>
                        {"timeSheetDays" in timeSheetData ?
                            <div>
                                <Table className="venus-timesheet" bordered>
                                    <thead >
                                        <tr>
                                            <th className="venus-timesheet-header">
                                                Sunday
                                            </th>
                                            <th className="venus-timesheet-header">
                                                Monday
                                            </th>
                                            <th className="venus-timesheet-header">
                                                Tuesday
                                            </th>
                                            <th className="venus-timesheet-header">
                                                Wednesday
                                            </th>
                                            <th className="venus-timesheet-header">
                                                Thursday
                                            </th>
                                            <th className="venus-timesheet-header">
                                                Friday
                                            </th>
                                            <th className="venus-timesheet-header">
                                                Saturday
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {timeSheetData.timeSheetDays.map((week, weekNumber) => (
                                            <tr key={"trWeek_" + weekNumber}>
                                                {week[0] !== null ?
                                                    <td className={week[0][2] === todaysDate && !printingTimeSheet ? "venus-timesheet-date-cell-today" : null}>
                                                        <div className="venus-timesheet-date-cell">
                                                            <div className="venus-timesheet-date-cell-circle">
                                                                {week[0][0]}
                                                            </div>
                                                            <div>
                                                                <FontAwesomeIcon style={{ color: "gray" }} icon={faHourglassHalf} size={"lg"} />
                                                                {" "}
                                                                <input className="venus-timesheet-hours-input" value={week[0][3]} type="number" spellCheck="false" autoComplete="off" onChange={(e) => handleHourData(e, weekNumber, 0)} />
                                                            </div>
                                                            <div className="venus-timesheet-date-cell-item">
                                                                <TextareaAutosize value={week[0][4]} className="venus-timesheet-note-input" placeholder={printingTimeSheet ? "" : "Add Note . . ."} type="text" spellCheck="true" autoComplete="off" onChange={(e) => handleTimeSheetDataNotes(e, weekNumber, 0)} maxRows={4} minRows={1} maxLength="55" />
                                                            </div>
                                                        </div>
                                                    </td>
                                                    :
                                                    <td className="venus-timesheet-blank-date-cell">

                                                    </td>
                                                }
                                                {week[1] !== null ?
                                                    <td className={week[1][2] === todaysDate && !printingTimeSheet ? "venus-timesheet-date-cell-today" : null}>
                                                        <div className="venus-timesheet-date-cell">
                                                            <div className="venus-timesheet-date-cell-circle">
                                                                {week[1][0]}
                                                            </div>
                                                            <div>
                                                                <FontAwesomeIcon style={{ color: "gray" }} icon={faHourglassHalf} size={"lg"} />
                                                                {" "}
                                                                <input className="venus-timesheet-hours-input" value={week[1][3]} type="number" spellCheck="false" autoComplete="off" onChange={(e) => handleHourData(e, weekNumber, 1)} />
                                                            </div>
                                                            <div className="venus-timesheet-date-cell-item">
                                                                <TextareaAutosize value={week[1][4]} className="venus-timesheet-note-input" type="text" placeholder={printingTimeSheet ? "" : "Add Note . . ."} spellCheck="true" autoComplete="off" onChange={(e) => handleTimeSheetDataNotes(e, weekNumber, 1)} maxRows={4} minRows={1} maxLength="55" />
                                                            </div>
                                                        </div>

                                                    </td>
                                                    :
                                                    <td className="venus-timesheet-blank-date-cell">

                                                    </td>
                                                }
                                                {week[2] !== null ?
                                                    <td className={week[2][2] === todaysDate && !printingTimeSheet ? "venus-timesheet-date-cell-today" : null}>
                                                        <div className="venus-timesheet-date-cell">
                                                            <div className="venus-timesheet-date-cell-circle">
                                                                {week[2][0]}
                                                            </div>
                                                            <div>
                                                                <FontAwesomeIcon style={{ color: "gray" }} icon={faHourglassHalf} size={"lg"} />
                                                                {" "}
                                                                <input className="venus-timesheet-hours-input" value={week[2][3]} type="number" spellCheck="false" autoComplete="off" onChange={(e) => handleHourData(e, weekNumber, 2)} />
                                                            </div>
                                                            <div className="venus-timesheet-date-cell-item">
                                                                <TextareaAutosize value={week[2][4]} className="venus-timesheet-note-input" type="text" placeholder={printingTimeSheet ? "" : "Add Note . . ."} spellCheck="true" autoComplete="off" onChange={(e) => handleTimeSheetDataNotes(e, weekNumber, 2)} maxRows={4} minRows={1} maxLength="55" />
                                                            </div>
                                                        </div>

                                                    </td>
                                                    :
                                                    <td className="venus-timesheet-blank-date-cell">

                                                    </td>
                                                }
                                                {week[3] !== null ?
                                                    <td className={week[3][2] === todaysDate && !printingTimeSheet ? "venus-timesheet-date-cell-today" : null}>
                                                        <div className="venus-timesheet-date-cell">
                                                            <div className="venus-timesheet-date-cell-circle">
                                                                {week[3][0]}
                                                            </div>
                                                            <div>
                                                                <FontAwesomeIcon style={{ color: "gray" }} icon={faHourglassHalf} size={"lg"} />
                                                                {" "}
                                                                <input className="venus-timesheet-hours-input" value={week[3][3]} type="number" spellCheck="false" autoComplete="off" onChange={(e) => handleHourData(e, weekNumber, 3)} />
                                                            </div>
                                                            <div className="venus-timesheet-date-cell-item">
                                                                <TextareaAutosize value={week[3][4]} className="venus-timesheet-note-input" type="text" placeholder={printingTimeSheet ? "" : "Add Note . . ."} spellCheck="true" autoComplete="off" onChange={(e) => handleTimeSheetDataNotes(e, weekNumber, 3)} maxRows={4} minRows={1} maxLength="55" />
                                                            </div>
                                                        </div>

                                                    </td>
                                                    :
                                                    <td className="venus-timesheet-blank-date-cell">

                                                    </td>
                                                }
                                                {week[4] !== null ?
                                                    <td className={week[4][2] === todaysDate && !printingTimeSheet ? "venus-timesheet-date-cell-today" : null}>
                                                        <div className="venus-timesheet-date-cell">
                                                            <div className="venus-timesheet-date-cell-circle">
                                                                {week[4][0]}
                                                            </div>
                                                            <div>
                                                                <FontAwesomeIcon style={{ color: "gray" }} icon={faHourglassHalf} size={"lg"} />
                                                                {" "}
                                                                <input className="venus-timesheet-hours-input" value={week[4][3]} type="number" spellCheck="false" autoComplete="off" onChange={(e) => handleHourData(e, weekNumber, 4)} />
                                                            </div>
                                                            <div className="venus-timesheet-date-cell-item">
                                                                <TextareaAutosize value={week[4][4]} className="venus-timesheet-note-input" type="text" placeholder={printingTimeSheet ? "" : "Add Note . . ."} spellCheck="true" autoComplete="off" onChange={(e) => handleTimeSheetDataNotes(e, weekNumber, 4)} maxRows={4} minRows={1} maxLength="55" />
                                                            </div>
                                                        </div>

                                                    </td>
                                                    :
                                                    <td className="venus-timesheet-blank-date-cell">

                                                    </td>
                                                }
                                                {week[5] !== null ?
                                                    <td className={week[5][2] === todaysDate && !printingTimeSheet ? "venus-timesheet-date-cell-today" : null}>
                                                        <div className="venus-timesheet-date-cell">
                                                            <div className="venus-timesheet-date-cell-circle">
                                                                {week[5][0]}
                                                            </div>
                                                            <div>
                                                                <FontAwesomeIcon style={{ color: "gray" }} icon={faHourglassHalf} size={"lg"} />
                                                                {" "}
                                                                <input className="venus-timesheet-hours-input" value={week[5][3]} type="number" spellCheck="false" autoComplete="off" onChange={(e) => handleHourData(e, weekNumber, 5)} />
                                                            </div>
                                                            <div className="venus-timesheet-date-cell-item">
                                                                <TextareaAutosize value={week[5][4]} className="venus-timesheet-note-input" type="text" placeholder={printingTimeSheet ? "" : "Add Note . . ."} spellCheck="true" autoComplete="off" onChange={(e) => handleTimeSheetDataNotes(e, weekNumber, 5)} maxRows={4} minRows={1} maxLength="55" />
                                                            </div>
                                                        </div>

                                                    </td>
                                                    :
                                                    <td className="venus-timesheet-blank-date-cell">

                                                    </td>
                                                }
                                                {week[6] !== null ?
                                                    <td className={week[6][2] === todaysDate && !printingTimeSheet ? "venus-timesheet-date-cell-today" : null}>
                                                        <div className="venus-timesheet-date-cell">
                                                            <div className="venus-timesheet-date-cell-circle">
                                                                {week[6][0]}
                                                            </div>
                                                            <div>
                                                                <FontAwesomeIcon style={{ color: "gray" }} icon={faHourglassHalf} size={"lg"} />
                                                                {" "}
                                                                <input className="venus-timesheet-hours-input" value={week[6][3]} type="number" spellCheck="false" autoComplete="off" onChange={(e) => handleHourData(e, weekNumber, 6)} />
                                                            </div>
                                                            <div className="venus-timesheet-date-cell-item">
                                                                <TextareaAutosize value={week[6][4]} className="venus-timesheet-note-input" type="text" placeholder={printingTimeSheet ? "" : "Add Note . . ."} spellCheck="true" autoComplete="off" onChange={(e) => handleTimeSheetDataNotes(e, weekNumber, 6)} maxRows={4} minRows={1} maxLength="55" />
                                                            </div>
                                                        </div>

                                                    </td>
                                                    :
                                                    <td className="venus-timesheet-blank-date-cell">

                                                    </td>
                                                }
                                            </tr>

                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                            : null
                        }
                        <div className="venus-timesheet-total-hours">
                            Total Hours: {timeSheetTotalHours}
                        </div>
                    </div>
                </ModalBody>
                <Modal isOpen={nestedModal} toggle={toggleNested} size="sm">
                    <ModalBody style={{ textAlign: "center", fontWeight: "bold" }}>Changes successfully saved</ModalBody>
                    <ModalFooter style={{ display: "flex", justifyContent: "space-evenly" }}>
                        <Button color="success" onClick={toggleNested}>
                            <FontAwesomeIcon icon={faCircleCheck} size={"sm"} />
                        </Button>
                    </ModalFooter>
                </Modal>
                <ModalFooter style={{ display: "flex", justifyContent: "space-between", whiteSpace: "pre-wrap" }}>
                    {admin && access === 4 ?
                        <div style={{ display: "flex" }}>
                            <p style={{ fontSize: "24px", fontWeight: "bold", marginRight: "10px" }}>Approve</p>
                            <input className="venus-timesheet-checkbox" type="checkbox" checked={!!timeSheetApproved} onChange={(e) => setTimeSheetApproved(e.target.checked)} />
                        </div>
                        :
                        <div className="prevent-text-selection">
                            {"                                          "}
                        </div>
                    }
                    <Button color="danger" style={{ fontWeight: "bold" }} onClick={toggleTimeSheet}>Close</Button>
                    <div>
                        <Button color="success" style={{ fontWeight: "bold", marginRight: "10px" }} onClick={handlePrint}>Print</Button>
                        <Button color="success" style={{ fontWeight: "bold" }} onClick={handleSaveChanges}>Save Changes</Button>
                    </div>
                </ModalFooter>

            </Modal>

        </Fragment>
    )
}

export default VenusTimeSheet;