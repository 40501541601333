import React, { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth, signOut } from "firebase/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faRightFromBracket, faScrewdriverWrench } from "@fortawesome/free-solid-svg-icons";
import {
    Navbar,
    NavbarBrand,
    Button,
    Nav,
    NavItem,
    Dropdown,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,

} from "reactstrap";

import classNames from "classnames";

import "./components.css";
import "./Navbars/Navbars.css";

import logo from "../assets/aw-logo-only.png";


const Topbar = (props) => {
    const { app, toggleSidebar, access, userName, email, employeeID } = props;
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = () => setDropdownOpen((prevState) => !prevState);

    const navigate = useNavigate();

    const getApp = (appName) => {
        if (appName.includes("jupiter")) {
            return ["JUPITER", "DATABASE", "jupiter-topbar", "jupiter-brand-subtext"];
        } else if (appName.includes("mercury")) {
            return ["MERCURY", "TOOLS", "mercury-topbar", "mercury-brand-subtext"];
        } else if (appName.includes("venus")) {
            return ["VENUS", "FORMS", "venus-topbar", "venus-brand-subtext"];
        } else if (appName.includes("orbit")) {
            return ["ORBIT", "AVIMOSA WIRELESS", "", "brand-subtext"];
        } else {
            return ["ERROR", "", "", ""];
        }
    }

    const logout = () => {
        const auth = getAuth();
        signOut(auth)
            .then(() => {
                navigate("/");
            })
    }

    const appSettings = getApp(app)

    return (
        <div>
            <Navbar className={classNames("topbar", "shadow-sm", appSettings[2])} >
                <NavbarBrand className="brand" onClick={() => navigate("/orbit")}>
                    <img alt="logo" src={logo} style={{ width: 75, height: 75 }} />
                    <span>
                        <p className="brand-name">{appSettings[0]}</p>
                        <p className={appSettings[3]}>{appSettings[1]}</p>
                    </span>
                </NavbarBrand>

                <Nav style={{ justifyContent: "center !important" }}>
                    {employeeID === 99 ?
                        <div style={{ margin: "10px 5px 0px 0px", color: "black" }}>
                            <FontAwesomeIcon icon={faScrewdriverWrench} size="xl" />
                        </div>
                        : null
                    }
                    <div style={{ cursor: "default" }}>
                        {employeeID === 99 ?
                            <p style={{ fontSize: "14px", fontWeight: "bold", width: "75%" }}>{userName.split(" ")[0]}{" "}&#128526;&#128187;</p>
                            :
                            <p style={{ fontSize: "14px", fontWeight: "bold", width: "75%" }}>{userName}</p>
                        }

                        <p style={{ fontSize: "12px", fontWeight: "bold", marginTop: "-15px" }}>{email}</p>
                    </div>
                    <div className="topbar-buttons">
                        {access > 1 ?
                            <div>
                                <NavItem className="topbar-item">
                                    <Button onClick={toggleSidebar} className="topbar-sidebar-toggle-btn">
                                        <FontAwesomeIcon icon={faBars} size="2xl" />
                                    </Button>
                                </NavItem>
                            </div>
                            : null
                        }
                        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                            <DropdownToggle className="dropdown">
                                <FontAwesomeIcon icon={faRightFromBracket} size="2xl" />
                            </DropdownToggle>
                            <DropdownMenu direction="down">
                                <DropdownItem onClick={logout} >Log out</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </div>

                </Nav>
            </Navbar>
        </div>

    )
}

export default Topbar;