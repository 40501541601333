import React from "react";
import { Route, Routes } from "react-router-dom";

import SignIn from "./auth/SignIn";
import ProtectedRoute from "./auth/ProtectedRoute";
// apps
import Orbit from "./apps/Orbit";

import "./App.css";


const App = () => {

    return (
        <Routes>
            <Route path="*" element={<SignIn />} />
            <Route path="/orbit/*" element={<ProtectedRoute><Orbit /></ProtectedRoute>} />
        </Routes>
    );
};

export default App;