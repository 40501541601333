import React, { Fragment, useState, useRef, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Document, Page, pdfjs } from "react-pdf";
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import "react-pdf/dist/esm/Page/TextLayer.css";
import * as XLSX from "xlsx/xlsx";
import {
	Button,
	Accordion,
	AccordionBody,
	AccordionHeader,
	AccordionItem,
	ButtonDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	Table
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faDownload
} from "@fortawesome/free-solid-svg-icons";
import Loading from "../../components/Loading.js";
import classNames from "classnames";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// filter options
const ION_B_Filters = [["RU", 1], ["Cabinet #", 9] , ["IDF/Tunnel", 17], ["IP Controller", 8]];
const ERA_Filters = [["CAP ID", 2], ["RU Location", 4]];

const FIBER_STRAND_FILTERS = []

// set global variable; assign on load; used by global function
var sheet = null;

const getChildFilters = (filterBy) => {
	const addressOfCells = filterBy; // <--- THIS VALUE CAN BE CHANGED TO WHATEVER TO SET AS FILTER; IDEA: CREATE ANOTHER DDL TO USE AS A FILTER SELECTOR
	const columnName = Object.keys(sheet).find(key => sheet[key].v === addressOfCells);
	// CHECK THIS: childFilterList should get a row of values
	let childFilterList = Object.entries(sheet)
		.filter(([key, value]) => key.toString()[0] === columnName[0])
		.map(([key, value]) => value.v);

	if (filterBy === "RU") {
		for (let i = 1; i < childFilterList.length; i++) {
			
			childFilterList[i] = childFilterList[i].slice(0, childFilterList[i].length - 2);
		}
	}

	childFilterList = [...new Set(childFilterList)];
	childFilterList.shift();

	return childFilterList.sort();
}


const MercuryReportViewer = () => {
	const [toggleParentDDL, setToggleParentDDL] = useState(false);
	const [toggleChildDDL, setToggleChildDDL] = useState(false);
	const [childFilterList, setChildFilterList] = useState([]);
	const [enableChildFilterDDL, setEnableChildFilterDDL] = useState(true);
	const [parentFilterSelected, setParentFilterSelected] = useState([])
	const [childFilterSelected, setChildFilterSelected] = useState("");
	const [viewReportData, setViewReportData] = useState(false);
	const [numPages, setNumPages] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);
	const [renderPDF, setRenderPDF] = useState(true);

	const location = useLocation();
	const { report } = location.state;

	const navigate = useNavigate();
	const ref = useRef();

	var parentFilterList = [];

	// detect mobile
	var screenWidth = window.innerWidth;
	var screenHeight = window.innerHeight;
	var mobile = false;
	if (screenWidth < 450 || screenHeight < 800) {
		mobile = true;
	}

	const toggleParentClick = () => {
		setToggleParentDDL(!toggleParentDDL);
	}

	const toggleChildClick = () => {
		setToggleChildDDL(!toggleChildDDL);
	}

	const getParentFilters = (dasType) => {
		let das;
		switch (dasType) {
			case "ION-B":
				das = ION_B_Filters;
				break;
			case "ERA":
				das = ERA_Filters;
				break;
			default:
				console.log(">>> ERROR");
		}
		return das;
	}

	const selectParentFilter = (filterBy) => {
		setParentFilterSelected(filterBy);
		setChildFilterList(getChildFilters(filterBy[0]));
		setEnableChildFilterDDL(false);
		setToggleParentDDL(false);

		// reset child filter
		setChildFilterSelected("");
		setViewReportData(false);

	}

	const selectChildFilter = (filterName) => {
		setChildFilterSelected(filterName);
		setToggleChildDDL(false);
		setViewReportData(true);
	}

	const onDocumentLoadSuccess = ({ numPages }) => {
		setNumPages(numPages)
	}

	const closeReport = () => {
		navigate(-1);
	}

	const debounce = (func, wait, immediate) => {
		var timeout;
		return function () {
			var context = this, args = arguments;
			var later = function () {
				timeout = null;
				if (!immediate) func.apply(context, args);
			};
			var callNow = immediate && !timeout;
			clearTimeout(timeout);
			timeout = setTimeout(later, wait);
			if (callNow) func.apply(context, args);
		};
	};

	// handle screen rotation
	const handleScreenRotation = debounce(() => {
		if (mobile) {
			setRenderPDF(!renderPDF);
		}
	}, 250);

	useEffect(() => {
		window.addEventListener("resize", handleScreenRotation);
	})

	// detect report type
	if (report[0].fileType === "xlsx") {
		// set global sheet variable;
		sheet = report[1].Sheets[report[1].SheetNames[0]];

		// get general info from sheet
		parentFilterList = getParentFilters(report[0].das);
	}
	if (report[0].fileType === "pdf") {

	}

	const changePage = (e, num) => {
		e.preventDefault();
		if (pageNumber + num > 0 && pageNumber + num <= numPages) {
			setPageNumber(pageNumber + num);
		}
	}

	const linkClick = ({ pageNumber }) => {
		setPageNumber(pageNumber);
	}

	const downloadFile = (e) => {
		e.preventDefault();
		let alink = document.createElement('a');
		alink.href = URL.createObjectURL(report[1]);
		alink.download = report[0].fileName;
		alink.click();
	}

	return (
		<div id="mercury-report-viewer">
			<div>
				<div className="mercury-top-container">
					<p className="mercury-venue-name">{report[0].venue.replace("_", " ")}</p>
					<div className="mercury-btn-containers">
						<div onClick={downloadFile}>
							<FontAwesomeIcon icon={faDownload} className="mercury-download-icon" />
						</div>
						<Button color="warning" className="mercury-report-close-btn-container" onClick={() => closeReport()}><strong>X</strong></Button>
					</div>
				</div>
				<p className="mercury-report-title">Report: {report[0].fileName}</p>
			</div>
			{report[0].fileType === "xlsx" ?
				<Fragment>
					<div className="mercury-filters-container">

						{/* parent filter DDL */}
						<div className="mercury-ddl-btn-container">
							<ButtonDropdown isOpen={toggleParentDDL} toggle={toggleParentClick} className={classNames("mercury-ddl-btn")}>
								<DropdownToggle caret>
									{parentFilterSelected.length === 0 ?
										<Fragment>
											Filter By
										</Fragment>
										:
										parentFilterSelected[0]
									}
								</DropdownToggle>
								<DropdownMenu container="body">
									{parentFilterList.map((filterName) => (
										<DropdownItem key={filterName[0]}>
											<div style={{ cursor: "pointer" }} onClick={() => selectParentFilter(filterName)}>
												{filterName[0]}
											</div>
										</DropdownItem>
									))}
								</DropdownMenu>
							</ButtonDropdown>
						</div>

						{/* child filter DDL */}
						<div className="mercury-ddl-btn-container">
							<ButtonDropdown isOpen={toggleChildDDL} disabled={enableChildFilterDDL} toggle={toggleChildClick} className={classNames("mercury-ddl-btn")}>
								<DropdownToggle caret>
									{childFilterSelected === "" ?
										<Fragment>
											Filter
										</Fragment>
										:
										childFilterSelected
									}
								</DropdownToggle>
								<DropdownMenu container="body">
									{childFilterList.map((filterName) => (
										<DropdownItem key={filterName}>
											<div style={{ cursor: "pointer" }} onClick={() => selectChildFilter(filterName)}>
												{filterName}
											</div>
										</DropdownItem>
									))}
								</DropdownMenu>
							</ButtonDropdown>
						</div>
					</div>
					{/* general info section; create new component */}
					<div>
						<MercuryReportGeneral report={report[0]} />
					</div>
					<div>
						{viewReportData ?
							<div>
								<div>
									<MercuryReportData parentFilter={parentFilterSelected} childFilter={childFilterSelected} />
								</div>
								<div>
									<MercuryReportSchematic />
								</div>
							</div>
							:
							<div className="mercury-no-filter-selected">
								Select Filters to View Specefic Data
							</div>
						}
					</div>
				</Fragment>
				:
				null
			}
			{report[0].fileType === "pdf" ?
				<Fragment>
					{renderPDF ?
						<Fragment>
							<div className="mercury-pdf-container" ref={ref}>
								<Document className={classNames("mercury-pdf-doc")} onItemClick={linkClick} file={report[1]} onLoadSuccess={onDocumentLoadSuccess} >
									<Page pageNumber={pageNumber} width={ref.current?.getBoundingClientRect().width || undefined} height={ref.current?.getBoundingClientRect().height || undefined} />
								</Document>
							</div>
							<div className="mercury-pdf-button-container">
								<Button onClick={(e) => changePage(e, -1)}>Prev Page</Button>
								<p className="">
									Page {pageNumber} of {numPages}
								</p>
								<Button onClick={(e) => changePage(e, 1)}>Next Page</Button>
							</div>
						</Fragment>
						:
						<Fragment>
							<div className="mercury-pdf-container" ref={ref}>
								<Document className={classNames("mercury-pdf-doc")} onItemClick={linkClick} file={report[1]} onLoadSuccess={onDocumentLoadSuccess} >
									<Page pageNumber={pageNumber} width={ref.current?.getBoundingClientRect().width || undefined} height={ref.current?.getBoundingClientRect().height || undefined} />
								</Document>
							</div>
							<div className="mercury-pdf-button-container">
								<Button onClick={(e) => changePage(e, -1)}>Prev Page</Button>
								<p className="">
									Page {pageNumber} of {numPages}
								</p>
								<Button onClick={(e) => changePage(e, 1)}>Next Page</Button>
							</div>
						</Fragment>
					}
				</Fragment>
				:
				null
			}	
		</div>
	)
};
const MercuryReportGeneral = (props) => {
	const { report } = props;
	const [open, setOpen] = useState("");

	const toggle = (id) => {
		if (open === id) {
			setOpen();
		} else {
			setOpen(id);
		}
	};

	return (
		<div>
			{/* GENERAL INFO TAB */}
			<Accordion open={open} toggle={toggle} className={classNames("mercury-general-info-container")}>
				<AccordionItem>
					<AccordionHeader targetId="reportGeneralInfo" className={classNames("mercury-general-header")}>General Info</AccordionHeader>
					<AccordionBody accordionId="reportGeneralInfo">
						{/* Display all general info here */}
						<div className="mercury-general-data-container">
							<p>
								<b>Customer</b>: {report.customer}
							</p>
							<p>
								<b>Market</b>: {report.market}
							</p>
							<p>
								<b>Contractor</b>: {report.contractor}
							</p>
							<p>
								<b>DAS</b>: {report.das}
							</p>
							<p>
								<b>Venue</b>: {report.venue}
							</p>
							<p>
								<b>System Configuration</b>: {report.systemConfiguration}
							</p>
						</div>
					</AccordionBody>
				</AccordionItem>	
			</Accordion>
		</div>
	)
};

const MercuryReportData = (props) => {
	const { parentFilter, childFilter } = props;

	// make sure sheet is loaded
	if (sheet != null) {

	}
	const [open, setOpen] = useState("");

	const toggle = (id) => {
		if (open === id) {
			setOpen();
		} else {
			setOpen(id);
		}
	};

	const getSelectedFiltersText = () => {
		let selectedFilterText;

		if (parentFilter[0] !== "RU") {
			selectedFilterText = parentFilter[0] + " " + childFilter;
		} else {
			selectedFilterText = childFilter;
		}

		return selectedFilterText;
	}

	const getDataByRow = (rowNum, range) => {
		let resultArray = []

		//var dataObject = createDataObject(headerList);
		for (var colNum = range.s.c; colNum <= range.e.c; colNum++) {
			// DECODE CELLS HERE AND INSERT INTO DATAROW LIST:
			var cell = sheet[XLSX.utils.encode_cell({ r: rowNum, c: colNum })];
			resultArray.push(cell !== undefined ? cell.v : "");
		}

		return resultArray;
	}

	const getFilteredData = (col, row) => {
		let dataResult = []
		let ruA = "";
		let ruB = "";
		let ruCounter = 0;
		if (col[0] === "RU") {
			ruA = row + "-A";
			ruB = row + "-B";
		}

		// get headers for table
		var headerList = XLSX.utils.sheet_to_json(sheet, { header: 1 })[0];
		dataResult.push(headerList);

		// iterate rows where column is 
		var range = XLSX.utils.decode_range(sheet["!ref"]);

		for (var rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {
			var cell = sheet[XLSX.utils.encode_cell({ r: rowNum, c: col[1] })];
			// this applies to MIMO systems A, B
			if (cell !== undefined && col[0] === "RU" && ruCounter < 2) {
				if (cell.v === ruA) {
					// get row data for RU A
					dataResult.push(getDataByRow(rowNum, range));

					ruCounter += 1;
				}
				if (cell.v === ruB) {
					// get row data for RU B
					dataResult.push(getDataByRow(rowNum, range));

					ruCounter += 1;
				}
				if (ruCounter === 2) {
					break;
				}
			} else if (cell !== undefined && cell.v === row) {

				break;
			}
		}
		
		return dataResult;
	}

	const dataTable = getFilteredData(parentFilter, childFilter);
	//const selectedFilterText = getSelectedFiltersText();

	return (
		<div>
			<div className="">
				<p className="mercury-selected-data-name">
					Viewing Report Data For: {getSelectedFiltersText()}
				</p>
			</div>
			{dataTable.length > 0 ?
				<Accordion open={open} toggle={toggle} className={classNames("mercury-general-info-container")}>
					<AccordionItem>
						<AccordionHeader targetId="reportFilteredData" className={classNames("mercury-general-header")}>Data</AccordionHeader>
						<AccordionBody accordionId="reportFilteredData">
							{/* Display filtered data here */}
							<div className="mercury-data-table-container">
								<Table bordered responsive>
									<thead>
										<tr>
											{dataTable[0].map((cell) => {
												// set appropriate header widths here
												

												return (
													<th className="table-header-fit">
														{cell}
													</th>
												)
											}
											)}
										</tr>
									</thead>
									<tbody>
										{/* map arrays */}
										{dataTable.slice(1).map((row) => (
											<tr>
												<th scope="row">
													{row[0]}
												</th>
												{/* map cells */}
												{row.slice(1).map((cell) => (
													<td>
														{cell}
													</td>
												))}
											</tr>
										))}
									</tbody>
								</Table>
							</div>
						</AccordionBody>
					</AccordionItem>
				</Accordion>
				:
				<div>

				</div>
			}
		</div>
	)
};

const MercuryReportSchematic = () => {


	return (
		<div>

		</div>
	)
};


export default MercuryReportViewer;