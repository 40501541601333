import React, { Fragment, useState, useEffect, useRef } from "react";
import { useReactToPrint } from 'react-to-print';
import { db } from "../../firebaseConfig";
import { collection, query, where, doc, getDocs, getDoc, updateDoc, addDoc, orderBy, Timestamp } from "firebase/firestore";
import {
    Button,
    Input,
    Label,
    ListGroup,
    ListGroupItem,
    Table,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faTrashCan,
    faCircleCheck
} from "@fortawesome/free-solid-svg-icons";
import TextareaAutosize from 'react-textarea-autosize';

import logo from "../../assets/aw-logo.png";

/*
    TODO:
        - Add prompt to ask user to save before closing

        - Investigate if bullet points can be added

    REQUEST:

*/

const getFormattedDate = (dateInput) => {
    let date = new Date(dateInput.seconds * 1000)

    var year = date.getFullYear();

    var month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;

    var day = date.getDate().toString();
    day = day.length > 1 ? day : '0' + day;

    return month + '/' + day + '/' + year;
};

const VenusQuote = (props) => {
    const { userID, access, admin } = props;

    const [quoteList, setQuoteList] = useState([]);
    const [quoteListAllUsers, setQuoteListAllUsers] = useState({});
    const [quoteData, setQuoteData] = useState({});
    const [quoteWorkItemsArray, setQuoteWorkItemsArray] = useState([]);
    const [clientInfo, setClientInfo] = useState([0]);
    const [newQuoteNumber, setNewQuoteNumber] = useState(0);
    const [quotePriceTotal, setQuotePriceTotal] = useState(0.0);
    const [updateQuote, setUpdateQuote] = useState(false);
    const [printingQuote, setPrintingQuote] = useState(false);

    const [modal, setModal] = useState(false);
    const [nestedModal, setNestedModal] = useState(false);

    const componentRef = useRef(null);
    const promiseResolveRef = useRef(null);

    const toggle = () => setModal(!modal);
    const toggleNested = () => setNestedModal(!nestedModal);

    var quoteUserType = admin ? "Employee Quotes" : "Your Quotes";

    const getQuoteList = async () => {
        let quoteList = [];
        let latestQuoteNumber = 0;
        let currentQuoteNumber = -1;

        const q = query(collection(db, "Venus_Quotes"), where("employeeID", "==", userID), orderBy("quoteNumber", "desc"));
        const docSnap = await getDocs(q);

        docSnap.forEach((quote) => {
            quoteList.push({ id: quote.id, ...quote.data() });

            // track latest quote number
            currentQuoteNumber = Number(quote.data().quoteNumber.toString().split("_")[2])
            latestQuoteNumber = currentQuoteNumber > latestQuoteNumber ? currentQuoteNumber : latestQuoteNumber;
        });

        setNewQuoteNumber(latestQuoteNumber + 1);

        if (quoteList.length > 0) {
            setQuoteList(quoteList);
        };
    };

    const getQuoteListAllUsers = async () => {
        let quoteListAll = {};
        let userFullName = "";
        let previousUserID = -1;
        let currentUserID = -1;

        const qAllQuotes = query(collection(db, "Venus_Quotes"), orderBy("employeeID"), orderBy("quoteNumber", "desc"));
        const docSnap = await getDocs(qAllQuotes);
        
        // structure: object -> array -> objects
        for (const quote of docSnap.docs) {
            currentUserID = quote.data().employeeID;

            if (currentUserID === userID) {
                continue;
            } else if (currentUserID === previousUserID) {
                // insert data
                quoteListAll[userFullName].push({ id: quote.id, ...quote.data() });

                currentUserID = quote.data().employeeID;
            } else {
                const qUserInfo = query(collection(db, "Users"), where("employeeID", "==", quote.data().employeeID));
                const docSnapUser = await getDocs(qUserInfo);

                for (const user of docSnapUser.docs) {
                    userFullName = user.data().firstName + "_" + user.data().lastName;
                }

                // initialize Object array
                quoteListAll[userFullName] = [];
                quoteListAll[userFullName].push({ id: quote.id, ...quote.data() });

                previousUserID = quote.data().employeeID;
            }
        }

        if (Object.keys(quoteListAll).length > 0) {
            setQuoteListAllUsers({ ...quoteListAll });
        };
    };

    const getQuoteData = async (quoteID) => { 
        setUpdateQuote(true);

        const docRef = doc(db, "Venus_Quotes", quoteID);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            let data = { id: docSnap.id, ...docSnap.data() };          

            data.quoteCreated = getFormattedDate(data.quoteCreated);

            orderWorkItems(data.workItem);

            setQuoteData(data);
            selectClient(data.quoteClientID);
            updateQuoteTotal(data.workItem);
        };
    };

    const orderWorkItems = (workItems) => {
        let workItemsArray = Array(Object.keys(workItems).length).fill("");
        let i = 0;

        Object.keys(workItems).forEach((key) => {
            i = Number(workItems[key][0]);
            workItemsArray[i - 1] = "item_" + i;
        });

        setQuoteWorkItemsArray(workItemsArray);
    }

    const updateQuoteTotal = (workItems) => {
        let quotePriceCounter = 0;

        Object.keys(workItems).forEach((item) => {
            quotePriceCounter += Number(workItems[item][2]);
        });

        setQuotePriceTotal(quotePriceCounter);
    };

    const handleQuoteData = (e) => {
        let itemNum = e.target.name.includes("_") ? e.target.name.split("_")[1].toString() : null;

        if (e.target.name.includes("workItemDescription")) {
            quoteData.workItem["item_" + itemNum][1] = e.target.value;
        } else if (e.target.name.includes("workItemPrice")) {
            quoteData.workItem["item_" + itemNum][2] = e.target.value;

            updateQuoteTotal(quoteData.workItem);
        } else {
            quoteData[e.target.name] = e.target.value; 
        };

        setQuoteData({ ...quoteData });
    };

    const handleCreateQuote = (event) => {
        setUpdateQuote(false);

        // clear client info
        setClientInfo([0]);

        // quote object
        const blankQuote = {
            quoteClientID: "0",
            projectID: "A&E", // <--- Possible change
            projectJobName: "",
            quoteDate: getFormattedDate(Timestamp.fromDate(new Date())),
            quoteNumber: "AW_" + new Date().getFullYear().toString() + "_" +  newQuoteNumber.toString().padStart(4, "0"),
            quoteRevision: 1,
            siteVenueID: "",
            workItem: {}
        }

        setQuotePriceTotal(0);
        setQuoteData({ ...blankQuote });
    };

    const handleSaveChanges = async (event) => {
        if (updateQuote) {
            // use quote auto-id to update doc
            const docRef = doc(db, "Venus_Quotes", quoteData.id);

            // convert Date to Timestamp - Timestamp.fromDate(new Date())

            await updateDoc(docRef, {
                projectID: quoteData.projectID,
                projectJobName: quoteData.projectJobName,
                quoteDate: quoteData.quoteDate,
                quoteNumber: quoteData.quoteNumber,
                quoteRevision: quoteData.quoteRevision,
                siteVenueID: quoteData.siteVenueID,
                workItem: quoteData.workItem,
                quoteClientID: quoteData.quoteClientID
            });
            
        } else {
            // create doc and save info
            await addDoc(collection(db, "Venus_Quotes"), {
                employeeID: userID,
                projectID: quoteData.projectID,
                projectJobName: quoteData.projectJobName,
                quoteDate: quoteData.quoteDate,
                quoteCreated: Timestamp.fromDate(new Date()),
                quoteNumber: quoteData.quoteNumber,
                quoteRevision: quoteData.quoteRevision,
                siteVenueID: quoteData.siteVenueID,
                workItem: quoteData.workItem,
                quoteClientID: quoteData.quoteClientID
            }).then((docRef) => {
                quoteData.id = docRef.id;
            }).catch((error) => {
                console.log(error);
            });

            setUpdateQuote(true);
            
            getQuoteList();
        }

        toggleNested();
    };

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: "AW_QT_AE_" + quoteData.projectJobName,
        onBeforeGetContent: () => {
            return new Promise((resolve) => {
                promiseResolveRef.current = resolve;
                setPrintingQuote(true);        
            });
        },
        onAfterPrint: () => {
            promiseResolveRef.current = null;
            setPrintingQuote(false);
        }
    });

    const handleAddWorkItem = (event) => {
        let currentWorkItemNumber = 0;
        let maxWorkItemNumber = 0;

        Object.keys(quoteData.workItem).map((keys) => {
            currentWorkItemNumber = Number(quoteData.workItem[keys][0]);
            maxWorkItemNumber = currentWorkItemNumber > maxWorkItemNumber ? currentWorkItemNumber : maxWorkItemNumber;
        });
        
        maxWorkItemNumber += 1;
        quoteData.workItem["item_" + maxWorkItemNumber.toString()] = [maxWorkItemNumber.toString(), "", 0];

        orderWorkItems(quoteData.workItem);
        setQuoteData({ ...quoteData });
    }

    const handleDeleteWorkItem = (event) => {
        let keyDelete = event.currentTarget.id.split("_")[1];

        delete quoteData.workItem["item_" + keyDelete];
        quoteWorkItemsArray.splice(quoteWorkItemsArray.indexOf("item_" + keyDelete), 1);

        // set new keys and assign order
        for (const key of Object.keys(quoteData.workItem)) {
            if (Number(key.split("_")[1]) > Number(keyDelete)) {
                let newKey = "item_" + (Number(key.split("_")[1]) - 1)

                Object.defineProperty(quoteData.workItem, newKey, Object.getOwnPropertyDescriptor(quoteData.workItem, key));
                
                delete quoteData.workItem[key];
                quoteData.workItem[newKey][0] = newKey.split("_")[1];
            }
        }

        orderWorkItems(quoteData.workItem);
        updateQuoteTotal(quoteData.workItem);
        setQuoteData({ ...quoteData });
    };

    const handleInputChange = (event) => {
        let clientSelected = event.target.value
        selectClient(clientSelected);
    };

    // client info - transfer to db
    const selectClient = (client) => {
        let clientInfo = [];
        switch (Number(client)) {
            case 1:
                clientInfo.push("1");
                clientInfo.push("Chris Brooksbank");
                clientInfo.push("Manager Network Construction");
                clientInfo.push("Ph: 949-540-8411");
                clientInfo.push("Email: Chris.Brooksbank@crowncastle.com");
                break;
            case 2:
                clientInfo.push("2");
                clientInfo.push("James Bell");
                clientInfo.push("Construction Manager-Venues-SoCal");
                clientInfo.push("Ph: 808-445-7338");
                clientInfo.push("Email: James.Bell@crowncastle.com");
                break;
            case 3:
                clientInfo.push("3");
                clientInfo.push("Carlo Vinicio Angulo");
                clientInfo.push("Network Construction Manager - Venues");
                clientInfo.push("Ph: 818-425-4966");
                clientInfo.push("Email: carlo.angulo@crowncastle.com");
                break;
            case 4:
                clientInfo.push("4");
                clientInfo.push("Steven Koutsoudi");
                clientInfo.push("Construction Manager");
                clientInfo.push("Ph: 951-293-1415");
                clientInfo.push("Email: Steven.Koutsoudis.Contractor@crowncastle.com");
                break;
            default:
                console.log("No client selected");
        }

        setClientInfo(clientInfo);
        quoteData.quoteClientID = Number(clientInfo[0]);
    }

    // get users lists of quotes
    useEffect(() => {
        if (admin) {
            getQuoteListAllUsers()
        } else {
            getQuoteList();
        }
    }, []);

    // set printing state
    useEffect(() => {
        if (printingQuote && promiseResolveRef.current) {
            // Resolves the Promise, letting `react-to-print` know that the DOM updates are completed
            promiseResolveRef.current();
        }
    }, [printingQuote]);

    return (
        <Fragment>
            <div>
                <div className="venus-app-header">
                    <div style={{ fontSize: "26px", fontWeight: "bold", marginTop: "0px" }}>{quoteUserType}</div>
                    {!admin ?
                        <Fragment>
                            <Button color="primary" style={{ fontWeight: "bold" }} onClick={() => { toggle(); handleCreateQuote() }}>+ Create New Quote</Button>
                        </Fragment>
                        : null
                    } 
                </div>
                {admin && access === 4 ?
                    <Fragment>
                        {Object.keys(quoteListAllUsers).length > 0 ?
                            <Fragment>
                                {Object.keys(quoteListAllUsers).map((userQuotes) => (
                                    <div key={userQuotes}>
                                        <Label className="venus-quote-list-user">{userQuotes.replace("_", " ")}</Label>
                                        <ListGroup style={{ margin: "0px 20px 0px 20px" }}>
                                            {quoteListAllUsers[userQuotes].map((quotes) => (
                                                <ListGroupItem className="venus-quote-list-item" key={quotes.id} action tag="button" onClick={() => { toggle(); getQuoteData(quotes.id) }}>
                                                    <div>
                                                        Project: {quotes.projectJobName}
                                                    </div>
                                                    <div>
                                                        Quote Number: {quotes.quoteNumber}
                                                    </div>
                                                </ListGroupItem>
                                            ))}
                                        </ListGroup>
                                    </div>
                                ))}
                            </Fragment>
                            :
                            <div className="venus-quote-no-quotes">
                                No quotes to display
                            </div>
                        }
                    </Fragment>
                    :
                    <Fragment>
                        {quoteList.length > 0 ?
                            <div>
                                <ListGroup style={{ margin: "0px 20px 0px 20px" }}>
                                    {quoteList.map((quotes) => (
                                        <ListGroupItem className="venus-quote-list-item" key={quotes.id} action tag="button" onClick={() => { toggle(); getQuoteData(quotes.id) }}>
                                            <div>
                                                Project: {quotes.projectJobName}
                                            </div>
                                            <div>
                                                Quote Number: {quotes.quoteNumber}
                                            </div>
                                        </ListGroupItem>
                                    ))}
                                </ListGroup>
                            </div>
                            :
                            <div className="venus-quote-no-quotes">
                                No quotes to display
                            </div>
                        }
                    </Fragment>      
                }
            </div>

            <Modal isOpen={modal} toggle={toggle} size="lg" backdrop="static" >
                <ModalHeader toggle={toggle}>
                    <div>
                        <Label for="ddlClient" style={{fontWeight: "bold"}}>Client</Label>
                        <Input type="select" name="ddl" id="ddlClient" onChange={handleInputChange} placeholder="Select client" value={clientInfo[0]}>
                            <option hidden value="0">Select client</option>
                            <option value="1">Chris Brooksbank</option>
                            <option value="2">James Bell</option>
                            <option value="3">Carlo Vinicio Angulo</option>
                            <option value="4">Steven Koutsoudi</option>
                        </Input>
                    </div>
                </ModalHeader>

                <ModalBody style={{ padding: "0px 0px 0px 0px !important" }}>
                    <div id="venusQuote" ref={componentRef}>
                        <div className="venus-quote-modal-header">
                            <div style={{display: "flex"}}>
                                <img alt="logo" src={logo} style={{ width: 75, height: 75 }} />
                                <p style={{fontWeight: "bold", fontSize: "38px", marginTop: "10px"}}>Quote</p>
                            </div>
                        </div>
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                        <div style={{width: "45%"}}>
                            <Table className="venus-quote-client-info-table" bordered size="sm">
                                <thead>
                                    <tr>
                                        <th style={{ textAlign: "center", fontSize: "16px", color: "white", backgroundColor: "#1fbcff" }}>
                                            Avimosa Wireless LLC
                                        </th>
                                    </tr>
                                </thead>
                                <tbody style={{textAlign: "center"}}>
                                    <tr>
                                        <td>
                                            1013 Roble Place, Montebello, CA 90640
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Ph: 213-300-7298, Email: avi@avimosa.com
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                            {clientInfo[0] !== 0 ?
                                <Table className="venus-quote-client-info-table" bordered size="sm">
                                    <thead>
                                        <tr>
                                            <th style={{ textAlign: "center", fontSize: "16px", color: "white", backgroundColor: "#1fbcff"}}>
                                                Client
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td id="quoteClientName">
                                                {clientInfo[1]}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td id="quoteClientTitle">
                                                {clientInfo[2]}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td id="quoteClientPhone">
                                                {clientInfo[3]}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td id="quoteClientEmail">
                                                {clientInfo[4]}
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                                :
                                <div className="venus-quote-no-quotes">
                                    Select a client
                                </div>
                            }
                            
                        </div>
                        <div style={{marginRight: "10%", width: "40%"}}>
                            <Table className="venus-quote-client-info-table" bordered size="sm">
                                <tbody>
                                    <tr style={{cursor: "default"}}>
                                        <td style={{textAlign: "right", width: "35%"}}>
                                            Date:
                                        </td>
                                        <td style={{ textAlign: "left" }}>
                                            {quoteData.quoteDate}
                                        </td>
                                    </tr>
                                    <tr style={{ cursor: "default" }}>
                                        <td style={{ textAlign: "right" }}>
                                            Quote #:
                                        </td>
                                        <td style={{ textAlign: "left" }}>
                                            {quoteData.quoteNumber}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: "right", cursor: "default" }}>
                                            Revision #:
                                        </td>
                                        <td style={{ textAlign: "left" }}>
                                            <input name="quoteRevision" value={quoteData.quoteRevision} type="text" spellCheck="false" autoComplete="off" onChange={handleQuoteData} />
                                        </td>
                                    </tr>
                                    <tr style={{ cursor: "default" }}>
                                        <td style={{ textAlign: "right" }}>
                                            Project ID:
                                        </td>
                                        <td style={{ textAlign: "left" }}>
                                            {quoteData.projectID}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: "right", cursor: "default" }}>
                                            Project Job Name:
                                        </td>
                                        <td style={{ textAlign: "left" }}>
                                                <TextareaAutosize name="projectJobName" value={quoteData.projectJobName} style={{ width: "180px", resize: "none", border: "none", outline: "none", overflow: "hidden" }} type="text" spellCheck="false" autoComplete="off" onChange={handleQuoteData} maxRows={2} minRows={2} maxLength="30" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: "right", cursor: "default" }}>
                                            Site ID/Venue:
                                        </td>
                                        <td style={{ textAlign: "left" }}>
                                            <input name="siteVenueID" value={quoteData.siteVenueID} type="text" spellCheck="false" autoComplete="off" onChange={handleQuoteData} />
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </div>
                    {"workItem" in quoteData && Object.keys(quoteData.workItem).length > 0 ?
                        <div>
                            <Table bordered>
                                <thead>
                                    <tr>
                                        <th id="colItemNum" style={{ width: "10%", textAlign: "center" }}>
                                            Item #                                        
                                        </th>
                                        <th style={{ textAlign: "center" }}>
                                            Description
                                        </th>
                                        <th style={{ width: "20%", textAlign: "center" }}>
                                            Price
                                        </th>
                                        {!printingQuote ?
                                            <th style={{ width: "10%", textAlign: "center" }}>
                                            
                                            </th> : null
                                        }
                                    </tr>
                                    
                                </thead>
                                <tbody>
                                    {quoteWorkItemsArray.map((workItemKey) => (
                                        <tr key={workItemKey}>
                                            <th scope="row" style={{ textAlign: "center" }}>
                                                {quoteData.workItem[workItemKey][0]}
                                            </th>
                                            <td className="venus-quote-workitem">
                                                <TextareaAutosize id="taWorkitemDescription" name={"workItemDescription_" + quoteData.workItem[workItemKey][0]} style={{ width: "100%", border: "0", fontSize: "12px" }} value={quoteData.workItem[workItemKey][1]} spellCheck="true" autoComplete="off" minRows="4" onChange={handleQuoteData} />
                                            </td>
                                            <td className="venus-quote-workitem" style={{ textAlign: "center", verticalAlign: "middle" }}>
                                                <input id="tbWorkitemPrice" name={"workItemPrice_" + quoteData.workItem[workItemKey][0]} value={quoteData.workItem[workItemKey][2]} style={{ textAlign: "center", width: "110px", border: "0" }} type="number" onChange={handleQuoteData} />
                                            </td>
                                            {!printingQuote ?
                                                <td style={{ textAlign: "center", verticalAlign: "middle" }}>
                                                    <Button id={"btnDelete_" + quoteData.workItem[workItemKey][0]}  color="warning" style={{ margin: "20px 20px" }} onClick={handleDeleteWorkItem}>
                                                        <FontAwesomeIcon icon={faTrashCan} size={"lg"} />
                                                    </Button>
                                                </td> : null
                                            }
                                        </tr>
                                    ))}
                                    <tr>
                                        <th>
                                        </th>
                                        <th>
                                        </th>
                                        <th style={{ textAlign: "center", verticalAlign: "middle" }}>
                                            Total: ${quotePriceTotal.toFixed(2)}
                                            </th>
                                        {!printingQuote ?
                                            <th>
                                            </th> : null
                                        }
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                        :
                        <div className="venus-quote-no-quotes">
                            No work items have been added yet
                        </div>
                    }
                    </div>
                </ModalBody>
                <Modal isOpen={nestedModal} toggle={toggleNested} size="sm">
                    <ModalBody style={{ textAlign: "center", fontWeight: "bold" }}>Changes successfully saved</ModalBody>
                    <ModalFooter style={{ display: "flex", justifyContent: "space-evenly" }}>
                        <Button color="success" onClick={toggleNested}>
                            <FontAwesomeIcon icon={faCircleCheck} size={"sm"} />
                        </Button>
                    </ModalFooter>
                </Modal>
                <ModalFooter style={{ display: "flex", justifyContent: "space-between" }}>
                    <Button color="primary" style={{ fontWeight: "bold" }} onClick={handleAddWorkItem}>+ Add Work Item</Button>
                    <Button color="danger" style={{ fontWeight: "bold" }} onClick={toggle}>Close</Button>
                    <div>
                        <Button color="success" style={{ fontWeight: "bold", marginRight: "10px" }} onClick={handlePrint}>Print</Button>
                        <Button color="success" style={{ fontWeight: "bold" }} onClick={handleSaveChanges}>Save Changes</Button>
                    </div>
                    
                    
                </ModalFooter>
                
            </Modal>
        </Fragment>
        
    )
};

export default VenusQuote;