import React, { Fragment, useEffect, useState } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { getDatabase, ref, orderByChild, query, onValue } from "firebase/database";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCircleInfo,
    faDownload,
    faFolderOpen,
    faScrewdriverWrench,
    faFileSignature,
    faFile,
    faFileExcel,
    faFileCsv,
    faFilePdf,
    faFilePowerpoint,
    faFileImage,
} from "@fortawesome/free-solid-svg-icons";
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Card,
    CardBody,
    CardTitle,
    UncontrolledPopover,
    PopoverHeader,
    PopoverBody
} from "reactstrap";
import { library } from "@fortawesome/fontawesome-svg-core";
import { isEqual } from "lodash";

import Topbar from "../components/Topbar";
import SideBar from "../components/SideBar";

import Jupiter from "./Jupiter/Jupiter";
import Venus from "./Venus/Venus";
import Mercury from "./Mercury/Mercury";
import MercuryReportViewer from "./Mercury/MercuryReportViewer";

import "./apps.css";

const realtimeDB = getDatabase();

library.add(faFile,
    faFileExcel,
    faFileCsv,
    faFilePdf,
    faFilePowerpoint,
    faFileImage
);

const Orbit = () => {
    const [isSidebarOpen, setSidebarOpen] = useState(false);

    var userData = JSON.parse(window.sessionStorage.userData);

    const { pathname } = useLocation();

    return (
        <div>
            <div className="two-comp-wrap">
                <SideBar isOpen={isSidebarOpen} toggleSidebar={() => setSidebarOpen(!isSidebarOpen)} />
                <div style={{ width: "100%" }}>
                    <Topbar app={pathname} toggleSidebar={() => setSidebarOpen(!isSidebarOpen)} access={userData.access} userName={userData.firstName + " " + userData.lastName} email={userData.email} employeeID={userData.employeeID} />
                    <div className="app-main-container" style={{ overflowScrolling: "touch", WebkitOverflowScrolling: "touch" }}>
                        <Routes>
                            <Route path="/" element={<OrbitApps userID={userData.employeeID} access={userData.access} role={userData.role} />} />
                            <Route path="/jupiter//*" element={<Jupiter userID={userData.employeeID} access={userData.access} role={userData.role} companyName={userData.companyName} />} />
                            <Route path="/mercury//*" element={<Mercury userID={userData.employeeID} access={userData.access} role={userData.role} companyName={userData.companyName} venue={userData.venue} />} />
                            {/*<Route path="/mercury/reportviewer" element={<MercuryReportViewer />} />*/}

                            <Route path="/venus//*" element={<Venus access={userData.access} role={userData.role} userID={userData.employeeID} userName={userData.firstName + " " + userData.lastName} />} access={userData.access} role={userData.role} />

                        </Routes>
                    </div> 
                    
                </div>
            </div>
        </div>
    )
};

const OrbitApps = (props) => {
    const { userID, access, role } = props;
    let navigate = useNavigate();

    const routeChange = (app) => {
        navigate(app);
    }

    return (
        <Fragment>
            <div className="app-container">
                <div className="app-container-label">
                    Recent File Uploads
                </div>
                <div className="app-app-container" style={{ padding: "0px" }}>
                    <RecentFiles/>
                </div>
            </div>
            <div className="app-container">
                <div className="app-container-label">
                    Applications
                </div>
                <div className="app-app-container">
                    <div className="app-app-subcontainer">
                        {access >= 1 && role > 1 ? 
                            <div id="jupiter" className="app" style={{ backgroundColor: "#eb9534" }} onClick={() => routeChange("/orbit/jupiter")}>
                                <div className="appIcon">
                                    <FontAwesomeIcon icon={faFolderOpen} className="icon" />
                                </div>
                                <div className="appName">
                                    Jupiter Database
                                </div>
                            </div>
                            : null
                        }

                        {(access >= 1 && role > 1) || (access === 0 && role === 0) ?
                            <div className="app" style={{ backgroundColor: "darkgray" }} onClick={() => routeChange("/orbit/mercury")}>
                                <div className="appIcon" >
                                    <FontAwesomeIcon icon={faScrewdriverWrench} className="icon" />
                                </div>
                                <div className="appName">
                                    Mercury Tools
                                </div>
                            </div>
                            : null
                        }
                
                        {access >= 1 && role >= 1 ?
                            <div className="app" style={{ backgroundColor: "#5ae661" }} onClick={() => routeChange("/orbit/venus")}>
                                <div className="appIcon">
                                    <FontAwesomeIcon icon={faFileSignature} className="icon" />
                                </div>
                                <div className="appName">
                                    Venus Forms
                                </div>
                            </div>
                            : null     
                        }
                    </div>
                </div>
            </div>
        </Fragment>
    )
};

const RecentFiles = (props) => {
    const [fileList, setFileList] = useState({});
    const [openFileData, setOpenFileData] = useState({});
    const [docToView, setDocToView] = useState("");

    const [fileView, setFileView] = useState([]);
    const [fileViewModal, setFileViewModal] = useState(false);

    const toggleFileView = () => setFileViewModal(!fileViewModal);

    // open file
    const handleOpenFile = (e, file) => {
        let encodedURI = "https://drive.google.com/viewerng/viewer?embedded=true&url=" + encodeURIComponent(file.downloadURL);

        setDocToView(encodedURI);
        setOpenFileData({ ...file });
    };

    const handleDownloadFile = (e, fileName, downloadURL) => {
        e.preventDefault();

        const xhr = new XMLHttpRequest();
        xhr.responseType = "blob";

        xhr.onload = (event) => {
            let alink = document.createElement("a");

            alink.href = URL.createObjectURL(xhr.response);
            alink.download = fileName;
            alink.click();
        };
        xhr.open('GET', downloadURL);
        xhr.send();
    };

    const handleCloseFile = (e) => {
        setFileView([]);
        setOpenFileData({});
        setDocToView([]);
    };

    useEffect(() => {
        const filesRef = ref(realtimeDB, "jupiter_recent_files");

        onValue(filesRef, (snapshot) => {
            const data = snapshot.val();
            if (!!data) {
                if (!isEqual(data, fileList)) {
                    setFileList({ ...data });
                }
            }
        })

    }, [props]);

    return (
        <Fragment>
            {Object.keys(fileList).length > 0 ?
                <div className="app-app-subcontainer" style={{ backgroundColor: "#efefef" }}>
                    {Object.keys(fileList).map((file) => (
                        <Fragment key={fileList[file].fileName}>
                            {"downloadURL" in fileList[file] ?
                                <Fragment>
                                    <Card className="jupiter-file">
                                        <FontAwesomeIcon icon={fileList[file].fileIcon} size="xl" className="jupiter-folder-icon" />
                                        <CardBody name={fileList[file].fileName} className="jupiter-folder-body">
                                            <CardTitle className="jupiter-file-title">
                                                {fileList[file].fileName}
                                            </CardTitle>
                                            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                                                <Button name={fileList[file].fileName} color="primary" className="jupiter-open-folder-btn" onClick={(e) => { toggleFileView(); handleOpenFile(e, fileList[file]); }}>
                                                    Open
                                                </Button>
                                                <Button id={fileList[file].fileNameValidDOM} color="success" style={{ marginRight: "3px" }} >
                                                    <FontAwesomeIcon icon={faCircleInfo} />
                                                </Button>
                                            </div>

                                        </CardBody>
                                    </Card>
                                    <UncontrolledPopover target={fileList[file].fileNameValidDOM} placement="right" trigger="legacy">
                                        <PopoverHeader>
                                            {fileList[file].fileName}
                                        </PopoverHeader>
                                        <PopoverBody>
                                            <p className="jupiter-file-popup-label">
                                                Description:
                                            </p>
                                            {fileList[file].fileDescription !== "" ?
                                                <p>
                                                    {fileList[file].fileDescription}
                                                </p>
                                                :
                                                <p style={{ textAlign: "center", color: "lightgray" }}>
                                                    No File Description provided
                                                </p>
                                            }
                                            <p className="jupiter-file-popup-label">
                                                File Size:
                                            </p>
                                            {fileList[file].fileSize}
                                            <div className="jupiter-file-popover-footer">
                                                <Button color="primary" onClick={(e) => handleDownloadFile(e, fileList[file].fileName, fileList[file].downloadURL)}>
                                                    <FontAwesomeIcon icon={faDownload} size="sm" />
                                                </Button>
                                            </div>
                                        </PopoverBody>
                                    </UncontrolledPopover>
                                </Fragment>
                                :
                                null
                            }
                        </Fragment>
                    ))}
                </div>
                :
                <div className="jupiter-no-item-text" style={{ margin: "0px", color: "white" }}>
                    No files have been recently uploaded . . .
                </div>
            }
            {Object.keys(openFileData).length > 0 ?
                <Fragment>
                    <Modal id="jupiterFileViewHeader" isOpen={fileViewModal} toggle={toggleFileView} fullscreen backdrop="static" >
                        <ModalHeader>
                            <div className="jupiter-file-view-title">
                                <p style={{ textAlign: "center", fontWeight: "bold", width: "100%" }}>
                                    {openFileData.fileName}
                                </p>
                            </div>
                            {/*<p style={{ userSelect: "none", color: "rgba(0, 0, 0, 0.0)" }}>*/}
                            {/*	{openFileData.fileName}*/}
                            {/*</p>*/}
                        </ModalHeader>
                        <ModalBody style={{ padding: "0px" }}>
                            <div className="jupiter-iframe-container">
                                <iframe id="jupiterIframe" title="File" className="jupiter-iframe" src={docToView} sandbox="allow-scripts allow-same-origin" ></iframe>
                            </div>
                        </ModalBody>
                        <ModalFooter style={{ display: "flex", justifyContent: "space-evenly", height: "60px" }}>
                            <Button color="danger" style={{ fontWeight: "bold" }} onClick={(e) => { handleCloseFile(e); toggleFileView() }}>
                                Close
                            </Button>
                            <div className="jupiter-open-file-info">
                                {/*<Button id={openFileData.fileNameValidDOM} color="secondary" style={{ marginRight: "5px" }} >*/}
                                {/*	<FontAwesomeIcon icon={faCircleInfo} />*/}
                                {/*</Button>*/}
                                <Button color="primary" onClick={(e) => handleDownloadFile(e, openFileData.fileName, openFileData.downloadURL)}>
                                    <FontAwesomeIcon icon={faDownload} />
                                </Button>
                            </div>
                        </ModalFooter>
                    </Modal>
                    <UncontrolledPopover target={openFileData.fileNameValidDOM} placement="top" trigger="legacy">
                        <PopoverHeader>
                            File Information
                        </PopoverHeader>
                        <PopoverBody>
                            <p className="jupiter-file-popup-label">
                                Description:
                            </p>
                            {openFileData.fileDescription !== "" ?
                                <p>
                                    {openFileData.fileDescription}
                                </p>
                                :
                                <p style={{ textAlign: "center", color: "lightgray" }}>
                                    No File Description provided
                                </p>
                            }
                            <p className="jupiter-file-popup-label">
                                File Size:
                            </p>
                            {openFileData.fileSize}
                            <div className="jupiter-file-popover-footer">

                            </div>
                        </PopoverBody>
                    </UncontrolledPopover>
                </Fragment>
                :
                null
            }
        </Fragment>
    )
}

export default Orbit;