import React, { Fragment } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCalculator
} from "@fortawesome/free-solid-svg-icons";

import MercuryPowerSharing from "./MercuryPowerSharing";

const Mercury = (props) => {
    const { access, role, userID, userName, companyName, venue } = props;
    const { state } = useLocation();

    return (
        <Fragment>
            <Routes>
                <Route path="/" element={<MercuryApps path="/" access={access} role={role} />} />
                <Route path="/powersharing" element={<MercuryPowerSharing userID={userID} access={access} admin={false} />} />
            </Routes>

            {/*{state !== null && state.hasOwnProperty("folderName") ?*/}
            {/*    <MercuryItemsContainer userCompany={company} userVenue={venue} userFolder={state.folderName} />*/}
            {/*    :*/}
            {/*    <MercuryItemsContainer userCompany={company} userVenue={venue} userFolder="" />*/}
            {/*}*/}
        </Fragment>
    )
};

const MercuryApps = (props) => {
    const { access, role } = props;
    let navigate = useNavigate();

    const routeChange = (app) => {
        navigate(app);
    }

    return (
        <Fragment>
            <div className="mercury-app-container">
                <div className="app-container-label">
                    Report Builders
                </div>
                <div className="mercury-app-container-item">
                    {access >= 0 ?
                        <div className="app" onClick={() => routeChange("/orbit/mercury/powersharing")}>
                            <div className="appIcon">
                                <FontAwesomeIcon icon={faCalculator} className="icon" />
                            </div>
                            <div className="appName">
                                Power Sharing
                            </div>
                        </div>
                        : null
                    }
                </div>
            </div>
        </Fragment>
    )
}

export default Mercury;