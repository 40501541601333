import React from "react";
import { Navigate } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { auth } from "../firebaseConfig";

const ProtectedRoute = ({ children }) => {
    const user = auth.currentUser;

    if (user === null) {
        let history = window.location.pathname
        let path = "/";
        if (history.includes("mercury")) {
            path = "/orbit/mercury";
        } else if (history.includes()) {
            path = "/orbit/jupiter"
        }
        return <Navigate to="/" state={{ historyPath: path}} />
    }
    return children;
}

export default ProtectedRoute;