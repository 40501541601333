import React from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import JupiterFileUpload from "./JupiterFileUpload";

import "../../components/FileTransfer/FileTransfer.css";

const JupiterDragAndDrop = (props) => {
	const { access, dbType, folderPath, filePath } = props;

	return (
		<DndProvider backend={HTML5Backend}>
			<JupiterFileUpload access={access} dbType={dbType} folderPath={folderPath} filePath={filePath} className="upload-container" />
		</DndProvider>
	)
};

export default JupiterDragAndDrop