import React, { Fragment, useState, useEffect, useRef } from "react";
import { getDatabase, ref, set, get, child, update, remove, onValue } from "firebase/database";
import { getStorage, ref as storageRef, deleteObject, listAll } from "firebase/storage";
import { isEqual } from "lodash-es";
import {
	Card,
	CardBody,
	CardTitle,
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Form,
	Row,
	Col,
	FormGroup,
	Label,
	Input,
	UncontrolledPopover,
	PopoverHeader,
	PopoverBody
} from "reactstrap";
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faFolder,
	faTableList,
	faCrown,
	faUsersRectangle,
	faFaceSmile,
	faFaceFrown,
	faWrench,
	faWifi,
	faFloppyDisk,
	faHouseChimneyWindow,
	faFile,
	faFileExcel,
	faFileCsv,
	faFilePdf,
	faFilePowerpoint,
	faFileImage,
	faCircleInfo,
	faPencil,
	faTrashCan,
	faDownload
} from "@fortawesome/free-solid-svg-icons";

import JupiterDragAndDrop from "./JupiterDragAndDrop.js";

library.add(faFolder,
	faTableList,
	faCrown,
	faUsersRectangle,
	faFaceSmile,
	faFaceFrown,
	faWrench,
	faWifi,
	faFloppyDisk,
	faHouseChimneyWindow,
	faFile,
	faFileExcel,
	faFileCsv,
	faFilePdf,
	faFilePowerpoint,
	faFileImage);

const realtimeDB = getDatabase();
const storage = getStorage();

const JupiterDatabase = (props) => {
	const { userID, access, role, companyName, dbType } = props;

	// folder states
	const [folderTree, setFolderTree] = useState({});
	const [folderData, setFolderData] = useState({});
	const [openFolderData, setOpenFolderData] = useState({});
	const [selectColor, setSelectColor] = useState("blank");
	const [selectIcon, setSelectIcon] = useState("fa-folder");
	const [deleteFolderConfirmationCode, setDeleteFolderConfirmationCode] = useState(["", "", ""]);
	const [deleteFolderConfirmationInput, setDeleteFolderConfirmationInput] = useState(["", "", ""])
	const [enableDelete, setEnableDelete] = useState(true);
	const [deleteOption, setDeleteOption] = useState("deleteEverything");

	// files states
	const [fileTree, setFileTree] = useState({});
	const [openFileData, setOpenFileData] = useState({});
	

	// file view states
	const [fileView, setFileView] = useState([]);
	const [docToView, setDocToView] = useState("");

	// UI states
	//const [databaseType, setDatabaseType] = useState(global);
	const [dbTypeText, setDbTypeText] = useState("");
	const [folderPath, setFolderPath] = useState("");
	const [createFolderModal, setCreateFolderModal] = useState(false);
	const [openFolderModal, setOpenFolderModal] = useState(false);
	const [fileViewModal, setFileViewModal] = useState(false);
	const [deleteFolderModal, setDeleteFolderModal] = useState(false);
	const [fileIframeLoaded, setFileIframeLoaded] = useState(true);

	// set rtdb paths
	let mainFolderPath;
	let mainFilePath;

	if (dbType === 0) {
		mainFolderPath = "jupiter_user_folders/" + userID + "_folders/";
		mainFilePath = "jupiter_user_files/" + userID + "_files/";
	} else if (dbType === 1) {
		mainFolderPath = "jupiter_repository_folders/";
		mainFilePath = "jupiter_repository_files/";
	} else if (dbType === 2) {
		mainFolderPath = "jupiter_database_folders/";
		mainFilePath = "jupiter_database_files/";
	}

	// firebase refs
	const foldersRef = ref(realtimeDB, mainFolderPath);
	const openFolderRef = ref(realtimeDB, mainFolderPath + folderPath.replace(" > ", "/"));
	const filesRef = ref(realtimeDB, mainFilePath + folderPath.replace(" > ", "/"));

	// modal toggles
	const toggleCreateFolder = () => setCreateFolderModal(!createFolderModal);
	const toggleOpenFolder = () => setOpenFolderModal(!openFolderModal);
	const toggleFileView = () => setFileViewModal(!fileViewModal);
	const toggleDeleteFolder = () => setDeleteFolderModal(!deleteFolderModal);

	onValue(foldersRef, (snapShot) => {
		const data = snapShot.val();
		if (!!data) {
			if (!isEqual(data, folderTree)) {
				setFolderTree({ ...data });
			}
		} else {
			// console.log("No data found");
		}
	});

	onValue(openFolderRef, (snapShot) => {
		const data = snapShot.val();
		if (!!data) {
			if (!isEqual(data, openFolderData)) {
				setOpenFolderData({ ...data });
			}
		} else {
			// console.log("No data found");
		}
	});

	if (folderPath !== mainFilePath) {
		onValue(filesRef, (snapShot) => {
			const data = snapShot.val();
			if (!!data) {
				if (!isEqual(data, fileTree)) {
					setFileTree({ ...data });
				}
			} else {
				//console.log("No data found");
			}
		});
	}

	// new folder info
	const handleFolderInfo = (e) => {
		folderData[e.target.name] = e.target.value.replace(/[/\].[,#$]/gi, "");

		setFolderData({ ...folderData });
	};

	const handleCreateNewFolder = (e, folderType) => {
		let blankFolderInfo = {};

		setSelectColor("blank");
		setSelectIcon("fa-folder");

		if (folderType === "parentFolder") {
			blankFolderInfo = {
				folderName: "",
				folderColor: "#ffffff",
				folderIcon: "fa-folder",
				folderParent: "",
				folderFiles: {},
				isSubFolder: false,
				userID: userID
			};
		} else {
			// create subfolder
			blankFolderInfo = {
				folderName: "",
				folderColor: "#ffffff",
				folderIcon: "fa-folder",
				folderParent: openFolderData.folderName,
				folderFiles: {},
				isSubFolder: true,
				userID: userID
			};
		};

		setFolderData({ ...blankFolderInfo });
	};

	const handleSaveNewFolder = () => {
		if (folderData.folderParent === "") {
			// add new folder
			set(ref(realtimeDB, mainFolderPath + folderData.folderName), folderData)
				.then(() => {
					// show modal saying folder was succesfully created

				}).catch((error) => {
					console.log(error);
				})
		} else {
			// create subfolder
			setFolderTree({ ...folderTree });

			set(ref(realtimeDB, mainFolderPath + folderPath.replaceAll(" > ", "/") + "/subFolders/" + folderData.folderName), folderData)
				.then(() => {

				}).catch((error) => {
					console.log(error);
				});
		}
	};

	const handleEditFolderInfo = () => {

	};

	const handleFolderColorChange = (e) => {
		let colorSelected = e.target.value;
		setSelectColor(colorSelected);
		folderData.folderColor = colorSelected;
	};

	const handleFolderIconChange = (e) => {
		let iconSelected = e.target.value;
		setSelectIcon(iconSelected)
		folderData.folderIcon = iconSelected;
	};

	// open folder, get info, delete
	const handleOpenFolder = (e, folderType) => {
		let selectedFolder = {};

		if (folderType === "parent") {
			selectedFolder = { parentFolderName: folderTree[e.target.name].folderParent, ...folderTree[e.target.name] };

			setFolderPath(selectedFolder.folderName);
		} else {
			setFolderPath(folderPath + "/subFolders/" + e.target.name);
		}

		setFileTree({});
	};

	const handleCloseSubFolder = (e) => {
		if (e.target.name === "") {
			toggleOpenFolder();
			setFolderPath("");
		} else {
			let newFolderPath = "";

			newFolderPath = folderPath.split("/subFolders/").slice(0, -1).join().replaceAll(",", "/subFolders/");
			setFolderPath(newFolderPath);
		};
	};

	const handleDeleteFolderPrompt = () => {
		// prep delete file validation
		const validDelete = [Math.floor(Math.random() * 10).toString(), Math.floor(Math.random() * 10).toString(), Math.floor(Math.random() * 10).toString()];
		setDeleteFolderConfirmationCode(validDelete);
		setDeleteFolderConfirmationInput(["", "", ""]);
		setEnableDelete(true);
	};

	const deleteFiles = (folderRef) => {
		listAll(folderRef)
			.then((res) => {
				res.items.forEach((itemRef) => {
					deleteObject(itemRef);
				})
				res.prefixes.forEach((folderRef) => {
					deleteFiles(folderRef);
				})
			})
	};

	const handleDeleteFolder = (e) => {
		toggleDeleteFolder();

		let completePath = mainFilePath + folderPath;
		completePath = completePath.split("/").slice(0, -1).join().replaceAll(",", "/");

		// delete from storage
		const listFolderRef = storageRef(storage, completePath);

		listAll(listFolderRef)
			.then((res) => {
				if (res.prefixes.length > 0) {
					res.prefixes.forEach((folderRef) => {
						if (folderRef.name === openFolderData.folderName) {
							deleteFiles(folderRef);
						}					
					})
				}
			}).catch((error) => {
				console.log(error)
			});

		// delete from rtdb
		if (deleteOption === "deleteEverything") {
			const dbRef = ref(getDatabase());
			get(child(dbRef, mainFilePath + folderPath))
				.then((snapshot) => {
					if (snapshot.exists()) {
						remove(snapshot.ref);
					} else {
						console.log("No data found")
					}
				}).catch((error) => {
					console.log(error);
				})
			get(child(dbRef, mainFolderPath + folderPath))
				.then((snapshot) => {
					if (snapshot.exists()) {
						remove(snapshot.ref);
					} else {
						console.log("No data found")
					}
				}).catch((error) => {
					console.log(error);
				})
		} else {
			const dbRef = ref(getDatabase());
			get(child(dbRef, mainFilePath + folderPath))
				.then((snapshot) => {
					if (snapshot.exists()) {
						remove(snapshot.ref);
					} else {
						console.log("No data found")
					}
				}).catch((error) => {
					console.log(error);
				})
		}

		setFolderTree({});

		if (folderPath.indexOf("/subFolders/") > 0) {
			e.target.name = "subfolder";
			handleCloseSubFolder(e);
		} else {
			e.target.name = "";
			handleCloseSubFolder(e)
		}
	};

	const handleDeleteFolderCode = (e) => {
		deleteFolderConfirmationInput[Number(e.target.name.split("_")[1])] = e.target.value;

		setDeleteFolderConfirmationInput(deleteFolderConfirmationInput);

		if (isEqual(deleteFolderConfirmationInput, deleteFolderConfirmationCode)) {
			setEnableDelete(false);
		}
	};

	// open file
	const handleOpenFile = (e, file) => {
		let encodedURI = "https://drive.google.com/viewerng/viewer?embedded=true&url=" + encodeURIComponent(file.downloadURL);

		setDocToView(encodedURI);
		setOpenFileData({ ...file });
	};

	const handleCloseFile = (e) => {
		setFileView([]);
		setOpenFileData({});
		setDocToView([]);
	};

	const handleDownloadFile = (e, fileName, downloadURL) => {
		e.preventDefault();

		const xhr = new XMLHttpRequest();
		xhr.responseType = "blob";

		xhr.onload = (event) => {
			let alink = document.createElement("a");

			alink.href = URL.createObjectURL(xhr.response);
			alink.download = fileName;
			alink.click();
		};
		xhr.open('GET', downloadURL);
		xhr.send();
	};

	const handleDeleteFile = (fileName, downloadURL) => {
		// delete from storage
		const fileRef = storageRef(storage, downloadURL);
		deleteObject(fileRef)
			.then(() => {
				
			}).catch((error) => {
				console.log(error);
			});

		// delete from rtdb
		remove(ref(realtimeDB, mainFilePath + folderPath.replaceAll(" > ", "/") + "/" + fileName));
		remove(ref(realtimeDB, "jupiter_recent_files/" + fileName));

	};

	const setDbTitle = (dbID) => {
		switch (dbID) {
			case 0:
				return "Your Files";
			case 1:
				return "AW Repository";
			case 2:
				return "AW Database";
			default:
				return "";
		}
	};

	useEffect(() => {
		let dbTitle = setDbTitle(dbType);
		setDbTypeText(dbTitle);
	}, [])

	return (
		<Fragment>
			<div className="app-header">
				<div style={{ fontSize: "26px", fontWeight: "bold", marginTop: "0px" }}>{dbTypeText}</div>
				<Button color="primary" style={{ fontWeight: "bold", maxHeight: "60px" }} onClick={(e) => { toggleCreateFolder(); handleCreateNewFolder(e, "parentFolder") }} >+ Create Folder</Button>
			</div>

			<Modal isOpen={createFolderModal} toggle={toggleCreateFolder} backdrop="static">
				<ModalBody>
					<div>
						<FormGroup>
							<Label className="app-form-label" for="tbFolderName">Folder Name</Label>
							<Input id="tbFolderName" name="folderName" type="text" spellCheck="false" autoComplete="off" value={folderData.folderName} onChange={(e) => handleFolderInfo(e)}>
							</Input>
							<Label className="app-form-label" for="ddlFolderColor">Folder Color</Label>
							<Input id="ddlFolderColor" name="folderColor" type="select" value={folderData.folderColor} onChange={handleFolderColorChange}>
								<option value="#ffffff">Default</option>
								<option value="#1572eb">Blue</option>
								<option value="#0ec43e">Green</option>
								<option value="#f73531">Red</option>
								<option value="#d142f5">Purple</option>
								<option value="#f5f242">Yellow</option>
							</Input>
							<Label className="app-form-label" for="ddlFolderIcon">Folder Icon</Label>
							<Input id="ddlFolderIcon" name="folderIcon" type="select" value={folderData.folderIcon} onChange={handleFolderIconChange}>
								<option value="fa-folder">Folder</option>
								<option value="fa-table-list">Table</option>
								<option value="fa-wifi">Wifi</option>
								<option value="fa-wrench">Wrench</option>
								<option value="fa-crown">Crown</option>
								<option value="fa-floppy-disk">Floppy Disk</option>
								<option value="fa-face-smile">Smiley Face</option>
								<option value="fa-face-frown">Frowning Face</option>
								<option value="fa-house-chimney-window">House</option>
							</Input>
						</FormGroup>
					</div>
				</ModalBody>
				<ModalFooter style={{ display: "flex", justifyContent: "space-evenly" }} >
					<Button color="danger" style={{ fontWeight: "bold" }} onClick={toggleCreateFolder}>Close</Button>
					<Button color="success" onClick={() => { toggleCreateFolder(); handleSaveNewFolder(); }}>Create Folder</Button>
				</ModalFooter>
			</Modal>

			<Modal isOpen={openFolderModal} toggle={toggleOpenFolder} className="jupiter-folder-modal" size="lg" backdrop="static">
				<ModalHeader cssModule={{ 'modal-title': 'w-100 text-center jupiter-modal-header' }}>
					<div style={{ display: "flex", justifyContent: "left", margin: "5px 5px 0px 5px" }}>
						<p style={{ fontSize: "16px", fontWeight: "bold", marginTop: "20px" }}>{folderPath.replaceAll("/subFolders/", " > ")}</p>
					</div>
					
					<div style={{ display: "flex", minWidth: "fit-content" }}>
						<JupiterDragAndDrop access={access} dbType={dbType} folderPath={mainFolderPath + folderPath.replaceAll(" > ", "/")} filePath={mainFilePath} />
						<Button color="primary" style={{ fontWeight: "bold", height: "40px", marginTop: "15px" }} onClick={(e) => { toggleCreateFolder(); handleCreateNewFolder(e, "subFolder") }} >
							+ Create Sub Folder
						</Button>
					</div>
				</ModalHeader>
				<ModalBody>

					{/* Sub Folder Render*/}
					{"subFolders" in openFolderData ?
						<Fragment>
							<div className="jupiter-folder-file-label">
								<p>Folders</p>
							</div>

							<div className="jupiter-folders-container">
								{Object.keys(openFolderData.subFolders).map((subFolderName) =>
									<Card key={subFolderName} className="jupiter-folder" style={{ backgroundColor: openFolderData.subFolders[subFolderName].folderColor, color: !["#f5f242", "#ffffff"].includes(openFolderData.subFolders[subFolderName].folderColor) ? "white" : "black" }}>
										<FontAwesomeIcon icon={openFolderData.subFolders[subFolderName].folderIcon} size="2xl" className="jupiter-folder-icon" />
										<CardBody name={subFolderName} className="jupiter-folder-body">
											<CardTitle className="jupiter-folder-title">
												{openFolderData.subFolders[subFolderName].folderName}
											</CardTitle>
											<Button name={openFolderData.subFolders[subFolderName].folderName} color={openFolderData.subFolders[subFolderName].folderColor === "#1572eb" ? "light" : "primary"} className="jupiter-open-folder-btn" onClick={(e) => { handleOpenFolder(e, "subfolder"); }}>
												Open
											</Button>
										</CardBody>
									</Card>
								)}
							</div>
						</Fragment>
						: null
					}

					<div className="jupiter-folder-file-label">
						<p>Files</p>
					</div>

					{/*File Render*/}
					<div className="jupiter-files-container">
						{ Object.keys(fileTree).length > 0 ?
							<Fragment>
								{Object.keys(fileTree).map((key) => (
									<Fragment key={key}>
										{"downloadURL" in fileTree[key] ?
											<Fragment>
												<Card className="jupiter-file">
													<FontAwesomeIcon icon={fileTree[key].fileIcon} size="xl" className="jupiter-folder-icon" />
													<CardBody name={fileTree[key].fileName} className="jupiter-folder-body">
														<CardTitle className="jupiter-file-title">
															{fileTree[key].fileName}
														</CardTitle>
														<div style={{ display: "flex", justifyContent: "space-evenly" }}>
															<Button name={fileTree[key].fileName} color="primary" className="jupiter-open-folder-btn" onClick={(e) => { toggleFileView(); handleOpenFile(e, fileTree[key]); }}>
																Open
															</Button>
															<Button id={fileTree[key].fileNameValidDOM} color="success" style={{ marginRight: "3px"}} >
																<FontAwesomeIcon icon={faCircleInfo} />
															</Button>
														</div>

													</CardBody>
												</Card>
												<UncontrolledPopover target={fileTree[key].fileNameValidDOM} placement="right" trigger="legacy">
													<PopoverHeader>
														{fileTree[key].fileName}
													</PopoverHeader>
													<PopoverBody>
														<p className="jupiter-file-popup-label">
															Description:
														</p>
														{fileTree[key].fileDescription !== "" ?
															<p>
																{fileTree[key].fileDescription}
															</p>
															:
															<p style={{ textAlign: "center", color: "lightgray" }}>
																No File Description provided
															</p>
														}
														<p className="jupiter-file-popup-label">
															File Size:
														</p>
														{fileTree[key].fileSize}
														<div className="jupiter-file-popover-footer">
															{access > 2 ?
																<Button className="jupiter-delete-file-btn" color="danger" onClick={() => handleDeleteFile(fileTree[key].fileName, fileTree[key].downloadURL)}>
																	<FontAwesomeIcon icon={faTrashCan} size="sm" />
																</Button>
																: null
															}
															<Button color="primary" onClick={(e) => handleDownloadFile(e, fileTree[key].fileName, fileTree[key].downloadURL)}>
																<FontAwesomeIcon icon={faDownload} size="sm" />
															</Button>
														</div>
													</PopoverBody>
												</UncontrolledPopover>
											</Fragment> : null
										}
									</Fragment>
								))}
							</Fragment>
							:
							<div className="jupiter-no-item-text">
								No files have been uploaded
							</div>
						}
					</div>

					{/*Delete Folder Prompt*/}
					<Modal isOpen={deleteFolderModal} toggle={toggleDeleteFolder} size="sm" backdrop="static">
						<ModalHeader id="deleteFolderPromptTitle">
							<div>
								<p style={{ fontWeight: "bold", fontSize: "20px" }}>
									Deleting Folder
								</p>
							</div>
						</ModalHeader>
						<ModalBody>
							<div>
								<Form>
									<div style={{ display: "flex", justifyContent: "space-evenly" }}>
										<Row>
											<Col>
												<Input id="deleteInput0" name="deleteConfirmation_0" onChange={(e) => handleDeleteFolderCode(e)} className="jupiter-delete-confirmation" type="text" maxLength="1" autoComplete="off" placeholder={deleteFolderConfirmationCode[0]} />
											</Col>
											<Col>
												<Input id="deleteInput1" name="deleteConfirmation_1" onChange={(e) => handleDeleteFolderCode(e)} className="jupiter-delete-confirmation" type="text" maxLength="1" autoComplete="off" placeholder={deleteFolderConfirmationCode[1]} />
											</Col>
											<Col>
												<Input id="deleteInput2" name="deleteConfirmation_2" onChange={(e) => handleDeleteFolderCode(e)} className="jupiter-delete-confirmation" type="text" maxLength="1" autoComplete="off" placeholder={deleteFolderConfirmationCode[2]} />
											</Col>
										</Row>
									</div>
									
									<br />

									<FormGroup>
										<FormGroup>
											<Input id="deleteEverything" name="radioDelete" type="radio" checked={deleteOption === "deleteEverything"} onChange={(e) => setDeleteOption(e.target.id)} />
											{"  "}
											<Label>
												Delete <strong>ALL</strong> files and folders
											</Label>
										</FormGroup>
										<FormGroup>
											<Input id="deleteFiles" name="radioDelete" type="radio" checked={deleteOption === "deleteFiles"} onChange={(e) => setDeleteOption(e.target.id)} />
											{"  "}
											<Label>
												Delete <strong>ONLY</strong> files within folders
											</Label>
										</FormGroup>
									</FormGroup>
								</Form>
							</div>
						</ModalBody>
						<ModalFooter style={{ display: "flex", justifyContent: "space-evenly"}}>
							<Button color="danger" disabled={enableDelete} onClick={handleDeleteFolder}>
								Delete
							</Button>
							<Button color="secondary" onClick={toggleDeleteFolder}>
								Cancel
							</Button>
						</ModalFooter>
					</Modal>

				</ModalBody>
				<ModalFooter style={{ display: "flex", justifyContent: "space-between" }} >
					{access > 2 ?
						<Button color="danger" onClick={(e) => { toggleDeleteFolder(); handleDeleteFolderPrompt() }}>
							<FontAwesomeIcon icon={faTrashCan} />
						</Button>
						: null
					}
					<Button name={openFolderData.folderParent} color="danger" style={{ fontWeight: "bold" }} onClick={handleCloseSubFolder}>Close</Button>
					{/*<Button color="secondary" onClick={(e) => handleEditFolderInfo(e)}>*/}
					{/*	<FontAwesomeIcon icon={faPencil} />*/}
					{/*</Button>*/}
				</ModalFooter>
			</Modal>

			{/*Parent Folder*/}
			{Object.keys(folderTree).length > 0 ?
				<div className="jupiter-folders-container">
					{Object.keys(folderTree).map((folder) => (
						<div key={folder}>
							{!folderTree[folder].isSubFolder ?
								<Card className="jupiter-folder" style={{ backgroundColor: folderTree[folder].folderColor, color: !["#f5f242", "#ffffff"].includes(folderTree[folder].folderColor) ? "white" : "black" }}>
									<FontAwesomeIcon icon={folderTree[folder].folderIcon} size="2xl" className="jupiter-folder-icon" />
									<CardBody name={folder} key={folder} className="jupiter-folder-body">
										<CardTitle className="jupiter-folder-title">
											{folderTree[folder].folderName}
										</CardTitle>
										<Button name={folder} color={folderTree[folder].folderColor === "#1572eb" ? "light" : "primary"} className="jupiter-open-folder-btn" onClick={(e) => { toggleOpenFolder(); handleOpenFolder(e, "parent"); }}>
											Open
										</Button>
									</CardBody>
								</Card>
								:
								null
							}
						</div>
					))}
				</div>
				:
				<div className="venus-quote-no-quotes">
					{dbType > 0 ? "..." : "..."}
				</div>
			}

			{/*View File*/}
			{Object.keys(openFileData).length > 0 ?
				<Fragment>
					<Modal id="jupiterFileViewHeader" isOpen={fileViewModal} toggle={toggleFileView} fullscreen backdrop="static" >
						<ModalHeader>
							<div className="jupiter-file-view-title">
								<p style={{ textAlign: "center", fontWeight: "bold", width: "100%" }}>
									{openFileData.fileName}
								</p>
							</div>
							{/*<p style={{ userSelect: "none", color: "rgba(0, 0, 0, 0.0)" }}>*/}
							{/*	{openFileData.fileName}*/}
							{/*</p>*/}
						</ModalHeader>
						<ModalBody style={{ padding: "0px"}}>
							<div className="jupiter-iframe-container">
								<iframe id="jupiterIframe" title="File" className="jupiter-iframe" src={docToView} sandbox="allow-scripts allow-same-origin" ></iframe>
							</div>
						</ModalBody>
						<ModalFooter style={{ display: "flex", justifyContent: "space-evenly", height: "60px" }}>
							<Button color="danger" style={{ fontWeight: "bold" }} onClick={(e) => { handleCloseFile(e); toggleFileView() }}>
								Close
							</Button>
							<div className="jupiter-open-file-info">
								{/*<Button id={openFileData.fileNameValidDOM} color="secondary" style={{ marginRight: "5px" }} >*/}
								{/*	<FontAwesomeIcon icon={faCircleInfo} />*/}
								{/*</Button>*/}
								<Button color="primary" onClick={(e) => handleDownloadFile(e, openFileData.fileName, openFileData.downloadURL)}>
									<FontAwesomeIcon icon={faDownload} />
								</Button>
							</div>
						</ModalFooter>
					</Modal>
					<UncontrolledPopover target={openFileData.fileNameValidDOM} placement="top" trigger="legacy">
						<PopoverHeader>
							File Information
						</PopoverHeader>
						<PopoverBody>
							<p className="jupiter-file-popup-label">
								Description:
							</p>
							{openFileData.fileDescription !== "" ?
								<p>
									{openFileData.fileDescription}
								</p>
								:
								<p style={{ textAlign: "center", color: "lightgray" }}>
									No File Description provided
								</p>
							}
							<p className="jupiter-file-popup-label">
								File Size:
							</p>
							{openFileData.fileSize}
							<div className="jupiter-file-popover-footer">

							</div>
						</PopoverBody>
					</UncontrolledPopover>
				</Fragment>
				:
				null
			}
		</Fragment>
	)
}

export default JupiterDatabase;