import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faHome,
    faLayerGroup,
    faPaperPlane,
    faFolderOpen,
    faScrewdriverWrench,
    faFileSignature,
    faQuestion
} from "@fortawesome/free-solid-svg-icons";
import { NavItem, Nav } from "reactstrap";
import classNames from "classnames";

import SubMenu from "./SubMenu";

const SideBar = (props) => {
    const { isOpen, toggleSidebar } = props;

    const navigate = useNavigate();

    const routeChange = (app) => {
        toggleSidebar(!isOpen);
        if (window.location.pathname !== app) {
            navigate(app);
        }
    }

    return (
        <div className={classNames("sidebar", { "is-open": isOpen })} >
            <div className={classNames("shadow-sm", "sidebar-header")}>
                <span color="info" style={{ color: "#fff" }}>
                    &times;
                </span>
                <h3></h3>
            </div>
            <div className="sidebar-group">
                <div>
                    <Nav vertical className={classNames("list-unstyled")}>
                        <NavItem onClick={() => routeChange("/orbit")} className="sidebar-item">
                            <FontAwesomeIcon icon={faHome} className="mr-2" />
                            Home
                        </NavItem>

                        {/*Add an admin layer group or future implmentations*/}
                        {/*<SubMenu title="Apps" icon={faLayerGroup} items={submenu[0]} />*/}

                        {/*App Siderbar Section*/}
                        <div className={classNames("static-menu", { "is-open": isOpen })}>
                            <div className="sidebar-apps-header">
                                <FontAwesomeIcon icon={faLayerGroup} className="mr-2" />
                                Apps
                            </div>
                            <NavItem className="submenu-items" onClick={() => routeChange("/orbit/jupiter")}>
                                <FontAwesomeIcon icon={faFolderOpen} />
                                Jupiter Database
                            </NavItem>
                            <NavItem className="submenu-items" onClick={() => routeChange("/orbit/mercury")}>
                                <FontAwesomeIcon icon={faScrewdriverWrench} />
                                Mercury Tools
                            </NavItem>
                            <NavItem className="submenu-items" onClick={() => routeChange("/orbit/venus")}>
                                <FontAwesomeIcon icon={faFileSignature} />
                                Venus Forms
                            </NavItem>
                        </div>
                    </Nav>
                </div>

                <div className="sidebar-footer">
                    <Nav vertical className={classNames("list-unstyled")}>
                        <NavItem className="sidebar-footer-item">
                            <FontAwesomeIcon icon={faQuestion} className="mr-2" />
                            About
                        </NavItem>
                        <NavItem className="sidebar-footer-item">
                            <FontAwesomeIcon icon={faPaperPlane} className="mr-2" />
                            Contact Us
                        </NavItem>
                    </Nav>
                </div>
            </div>
        </div>
    )
};

// keep this for future implementations
const submenu = [
    [
        {
            title: "Jupiter Data Base",
            target: "jupiterDataBase",
            appIcon: faFolderOpen
        },
        {
            title: "Mercury Reports",
            target: "mercury",
            appIcon: faScrewdriverWrench
        }
    ],
    [
        {
            title: "empty"
        }
    ]
];

export default SideBar;