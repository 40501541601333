import React, { Fragment } from "react";
import { Routes, Route, useNavigate} from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faDatabase,
    faNetworkWired,
    faServer
} from "@fortawesome/free-solid-svg-icons";

import JupiterDatabase from "./JupiterDatabase";

const Jupiter = (props) => {
	const { access, role, userID} = props;


	return (
		<Fragment>
			<Routes>
				<Route path="/" element={<JupiterApps path="/" access={access} role={role} />} />

                <Route path="/jupiter-your-files" element={<JupiterDatabase userID={userID} access={access} role={role} dbType={0} />} />
                <Route path="/jupiter-aw-repository" element={<JupiterDatabase userID={userID} access={access} role={role} dbType={1} />} />
                <Route path="/jupiter-aw-database" element={<JupiterDatabase userID={userID} access={access} role={role} dbType={2} />} />
			</Routes>

		</Fragment>
	)
};

const JupiterApps = (props) => {
    const { access, role } = props;
    let navigate = useNavigate();

    const routeChange = (app) => {
        navigate(app);
    }

    return (
        <Fragment>
            <div className="jupiter-app-container">
                <div className="app-container-label">
                    Storage
                </div>
                {role >= 2 ?
                    <div className="app" onClick={() => routeChange("/orbit/jupiter/jupiter-your-files")}>
                        <div className="appIcon">
                            <FontAwesomeIcon icon={faServer} className="icon" />
                        </div>
                        <div className="appName">
                            Your Files
                        </div>
                    </div>
                    : null
                }

                {access >= 2 ?
                    <div className="app" onClick={() => routeChange("/orbit/jupiter/jupiter-aw-repository")}>
                        <div className="appIcon">
                            <FontAwesomeIcon icon={faNetworkWired} className="icon" />
                        </div>
                        <div className="appName">
                            AW Repository
                        </div>
                    </div>
                    : null
                }

                {access >= 2 ?
                    <div className="app" onClick={() => routeChange("/orbit/jupiter/jupiter-aw-database")}>
                        <div className="appIcon">
                            <FontAwesomeIcon icon={faDatabase} className="icon" />
                        </div>
                        <div className="appName">
                            AW Database
                        </div>
                    </div>
                    : null
                }
            </div>
        </Fragment>
    )
};

export default Jupiter;