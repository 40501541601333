import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { auth, db } from "../firebaseConfig";
import { doc, getDoc } from "firebase/firestore";
import {
    signInWithEmailAndPassword,
    setPersistence,
    browserSessionPersistence
} from "firebase/auth";
import {
    FormGroup,
    Form,
    Label,
    Input,
    Button
} from "reactstrap";

import logo from "../assets/aw-logo.png";
import "../App.css";

const getUserInfo = async (uid) => {
    const docRef = doc(db, "Users", uid);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        return docSnap.data();
    } else {
        console.log("NO USER DATA FOUND");
    }

}

const SignIn = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errorPassword, setErrorPassword] = useState(false);
    const [errorTooMany, setErrorTooMany] = useState(false);
    const [errorGeneral, setErrorGeneral] = useState(false);
    const navigate = useNavigate();

    const location = useLocation();

    const signIn = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setPersistence(auth, browserSessionPersistence)
            .then(() => {
                signInWithEmailAndPassword(auth, email, password)
                    .then((userCredentials) => {
                        setErrorPassword(false);
                        setErrorTooMany(false);
                        setErrorGeneral(false);

                        getUserInfo(userCredentials.user.uid)
                            .then((res) => {
                                window.sessionStorage.userData = JSON.stringify(res);

                                // use history path
                                if (location.state !== null && location.state.historyPath !== null) {
                                    navigate(location.state.historyPath);
                                }
                                // >>>>> CHANGE THIS
                                if (res.access === "demo") {
                                    navigate("/orbit/mercury");
                                } else {
                                    navigate("/orbit");
                                }
                            })
                    }).catch((error) => {
                        console.log(error.code);
                        if (error.code === "auth/wrong-password") {
                            setErrorPassword(true);
                        } else if (error.code === "auth/too-many-requests") {
                            setErrorTooMany(true);
                        } else {
                            setErrorGeneral(true);
                        }
                    })
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
            });
    }

    return (
        <div className="signin-container">
            <div className="signin-background">

            </div>
            <div className="signin-subcontainer">
                <div className="auth-signin-container">
                    <div className="signin-logo-container">
                        <img className="app-logo" src={logo} alt="Avimosa logo" width="120" />
                        <div className="signin-logo-text">
                            Orbit
                        </div>
                        <br />
                    </div>
                    <Form>
                        <FormGroup floating style={{ margin: "0px 40px 0px 40px" }}>
                            <Input id="user-email" name="email" placeholder="Email" type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                            <Label for="user-email">
                                Email
                            </Label>
                        </FormGroup>
                        <FormGroup floating style={{ margin: "0px 40px 0px 40px" }}>
                            <Input id="user-password" name="password" placeholder="Password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                            <Label for="user-password">
                                Password
                            </Label>
                        </FormGroup>
                    </Form>
                    <Button color="primary" style={{ fontWeight: "bold" }} onClick={signIn}>
                        Sign In
                    </Button>
                    {errorPassword ?
                        <div className="auth-error">
                            Wrong email or password
                        </div>
                        :
                        null
                    }
                    {errorTooMany ?
                        <div className="auth-error">
                            Too many failed login attempts
                        </div>
                        :
                        null

                    }
                    {errorGeneral ?
                        <div className="auth-error">
                            Something went wrong when trying to login
                        </div>
                        :
                        null
                    }
                    <div className="signin-info-container">
                        For information about our services and team,
                        <br />
                        learn more about us <a href="https://www.avimosa.com/" rel="noreferrer" target="_blank">here</a>
                    </div>
                </div>

                <div className="signin-llc-container">
                    <div className="signin-llc">
                        &copy; 2024 AVIMOSA WIRELESS LLC
                        All rights reserved.
                    </div>
                </div>
            </div>
        </div>
        
    )
}

export default SignIn;