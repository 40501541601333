import React, { Fragment, useState, useEffect, useRef } from "react";
import { useReactToPrint } from 'react-to-print';
import { db } from "../../firebaseConfig";
import { collection, query, where, doc, getDocs, getDoc, updateDoc, addDoc, orderBy, Timestamp } from "firebase/firestore";
import {
    Button,
    Label,
    ListGroup,
    ListGroupItem,
    Table,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from "reactstrap";
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faTrashCan,
    faCircleCheck
} from "@fortawesome/free-solid-svg-icons";
import TextareaAutosize from 'react-textarea-autosize';

import logo from "../../assets/aw-logo.png";
import "react-datepicker/dist/react-datepicker.css";

const getFormattedDate = (dateInput) => {
    let date = new Date(dateInput.seconds * 1000)

    var year = date.getFullYear();

    var month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;

    var day = date.getDate().toString();
    day = day.length > 1 ? day : '0' + day;

    return month + '/' + day + '/' + year;
};

const VenusPT = (props) => {
    const { userID, access, admin } = props;

    const [ptList, setPTList] = useState([]);
    const [ptListAllUsers, setPTListAllUsers] = useState({});
    const [ptData, setPTData] = useState({});
    const [ptWorkItemsArray, setPTWorkItemsArray] = useState([]);
    const [newPTNumber, setNewPTNumber] = useState(0);
    const [updatePT, setUpdatePT] = useState(false);
    const [printingPT, setPrintingPT] = useState(false);

    const [modal, setModal] = useState(false);
    const [nestedModal, setNestedModal] = useState(false);

    const componentRef = useRef(null);
    const promiseResolveRef = useRef(null);

    const toggle = () => setModal(!modal);
    const toggleNested = () => setNestedModal(!nestedModal);

    var poUserType = admin ? "Employee Project Trackers" : "Your Project Trackers";

    const getPTList = async () => {
        // single PT
        let ptList = [];
        let latestPTNumber = 0;
        let currentPTNumber = -1;

        const q = query(collection(db, "Venus_ProjectTrackers"), where("employeeID", "==", userID), orderBy("ptNumber", "desc"));
        const docSnap = await getDocs(q);

        docSnap.forEach((pt) => {
            ptList.push({ id: pt.id, ...pt.data() });

            // track latest PT number
            currentPTNumber = Number(pt.data().ptNumber.toString().split("_")[4])
            latestPTNumber = currentPTNumber > latestPTNumber ? currentPTNumber : latestPTNumber;
        });

        setNewPTNumber(latestPTNumber + 1);

        if (ptList.length > 0) {
            setPTList(ptList);
        }
    };

    const getPTListAllUsers = async () => {
        let poListAll = {};
        let userFullName = "";
        let previousUserID = -1;
        let currentUserID = -1;

        const qAllPOs = query(collection(db, "Venus_ProjectTrackers"), orderBy("employeeID"), orderBy("ptNumber", "desc"));
        const docSnap = await getDocs(qAllPOs);

        // structure: object -> array -> objects
        for (const po of docSnap.docs) {
            currentUserID = po.data().employeeID;

            if (currentUserID === userID) {
                continue;
            } else if (currentUserID === previousUserID) {
                // insert data
                poListAll[userFullName].push({ id: po.id, ...po.data() });

                currentUserID = po.data().employeeID;
            } else {
                const qUserInfo = query(collection(db, "Users"), where("employeeID", "==", po.data().employeeID));
                const docSnapUser = await getDocs(qUserInfo);

                for (const user of docSnapUser.docs) {
                    userFullName = user.data().firstName + "_" + user.data().lastName;
                }

                // initialize Object array
                poListAll[userFullName] = [];
                poListAll[userFullName].push({ id: po.id, ...po.data() });

                previousUserID = po.data().employeeID;
            }
        }

        if (Object.keys(poListAll).length > 0) {
            setPTListAllUsers({ ...poListAll });
        };
    };

    const orderWorkItems = (workItems) => {
        let workItemsArray = Array(Object.keys(workItems).length).fill("");
        let i = 0;

        Object.keys(workItems).forEach((key) => {
            i = Number(workItems[key][0]);
            workItemsArray[i - 1] = "item_" + i;
        });

        setPTWorkItemsArray(workItemsArray);
    }

    const getPOData = async (ptID) => {
        setUpdatePT(true);

        const docRef = doc(db, "Venus_ProjectTrackers", ptID);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            let data = { id: docSnap.id, ...docSnap.data() };

            data.ptCreated = getFormattedDate(data.ptCreated);

            orderWorkItems(data.workItem);

            setPTData(data);
        };
    };

    const handlePOData = (e) => {
        let itemNum = e.target.name.includes("_") ? e.target.name.split("_")[1].toString() : null;

        if (e.target.name.includes("workItemDate")) {
            ptData.workItem["item_" + itemNum][1] = e.target.value;
        } else if (e.target.name.includes("workItemDescription")) {
            ptData.workItem["item_" + itemNum][2] = e.target.value;
        } else if (e.target.name.includes("workItemEngineer")) {
            ptData.workItem["item_" + itemNum][3] = e.target.value;
        } else {
            ptData[e.target.name] = e.target.value;
        };

        setPTData({ ...ptData });
    };

    const handleCreatePT = (e) => {
        setUpdatePT(false);

        // quote object
        const blankPT = {
            projectJobName: "",
            ptNumber: "AW_" + userID + "_PT_" + new Date().getFullYear().toString() + "_" + newPTNumber.toString().padStart(4, "0"),
            workItem: {}
        }

        setPTData({ ...blankPT });
    };

    const handleSaveChanges = async (e) => {
        if (updatePT) {
            // use PT auto-id to update doc
            const docRef = doc(db, "Venus_ProjectTrackers", ptData.id);

            await updateDoc(docRef, {
                projectJobName: ptData.projectJobName,
                ptNumber: ptData.ptNumber,
                workItem: ptData.workItem
            });

        } else {
            // create doc and save info
            await addDoc(collection(db, "Venus_ProjectTrackers"), {
                employeeID: userID,
                projectJobName: ptData.projectJobName,
                ptCreated: Timestamp.fromDate(new Date()),
                ptNumber: ptData.ptNumber,
                workItem: ptData.workItem
            }).then((docRef) => {
                ptData.id = docRef.id;
            }).catch((error) => {
                console.log(error);
            });

            setUpdatePT(true);

            getPTList();
        }

        toggleNested();
    };

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: "AW_PT_" + ptData.projectJobName,
        onBeforeGetContent: () => {
            return new Promise((resolve) => {
                promiseResolveRef.current = resolve;
                setPrintingPT(true);
            });
        },
        onAfterPrint: () => {
            promiseResolveRef.current = null;
            setPrintingPT(false);
        }
    });

    const handleAddWorkItem = (e) => {
        let currentWorkItemNumber = 0;
        let maxWorkItemNumber = 0;

        Object.keys(ptData.workItem).map((keys) => {
            currentWorkItemNumber = Number(ptData.workItem[keys][0]);
            maxWorkItemNumber = currentWorkItemNumber > maxWorkItemNumber ? currentWorkItemNumber : maxWorkItemNumber;
        });

        maxWorkItemNumber += 1;
        ptData.workItem["item_" + maxWorkItemNumber.toString()] = [maxWorkItemNumber.toString(), new Date(), "", ""];

        orderWorkItems(ptData.workItem);
        setPTData({ ...ptData});
    }

    const handleDeleteWorkItem = (e) => {
        let keyDelete = e.currentTarget.id.split("_")[1];

        delete ptData.workItem["item_" + keyDelete];
        ptWorkItemsArray.splice(ptWorkItemsArray.indexOf("item_" + keyDelete), 1);

        // set new keys and assign order
        for (const key of Object.keys(ptData.workItem)) {
            if (Number(key.split("_")[1]) > Number(keyDelete)) {
                let newKey = "item_" + (Number(key.split("_")[1]) - 1)

                Object.defineProperty(ptData.workItem, newKey, Object.getOwnPropertyDescriptor(ptData.workItem, key));

                delete ptData.workItem[key];
                ptData.workItem[newKey][0] = newKey.split("_")[1];
            }
        }

        orderWorkItems(ptData.workItem);
        setPTData({ ...ptData });
    };

    // get users lists of quotes
    useEffect(() => {
        if (admin) {
            getPTListAllUsers()
        } else {
            getPTList();
        }
    }, []);

    // set printing state
    useEffect(() => {
        if (printingPT && promiseResolveRef.current) {
            // Resolves the Promise, letting `react-to-print` know that the DOM updates are completed
            promiseResolveRef.current();
        }
    }, [printingPT]);

    return (
        <Fragment>
            <div>
                <div className="venus-app-header">
                    <div style={{ fontSize: "26px", fontWeight: "bold", marginTop: "0px" }}>{poUserType}</div>
                    {!admin ?
                        <Fragment>
                            <Button color="primary" style={{ fontWeight: "bold" }} onClick={() => { toggle(); handleCreatePT() }}>+ Create New PT</Button>
                        </Fragment>
                        : null
                    }
                </div>
                {admin && access === 4 ?
                    <Fragment>
                        {Object.keys(ptListAllUsers).length > 0 ?
                            <Fragment>
                                {Object.keys(ptListAllUsers).map((userPOs) => (
                                    <div key={userPOs}>
                                        <Label className="venus-quote-list-user">{userPOs.replace("_", " ")}</Label>
                                        <ListGroup style={{ margin: "0px 20px 0px 20px" }}>
                                            {ptListAllUsers[userPOs].map((POs) => (
                                                <ListGroupItem className="venus-quote-list-item" key={POs.id} action tag="button" onClick={() => { toggle(); getPOData(POs.id) }}>
                                                    <div>
                                                        Project: {POs.projectJobName}
                                                    </div>
                                                    <div>
                                                        Project Tracker #: {POs.ptNumber}
                                                    </div>
                                                </ListGroupItem>
                                            ))}
                                        </ListGroup>
                                    </div>
                                ))}
                            </Fragment>
                            :
                            <div className="venus-quote-no-quotes">
                                No Project Trackers to display
                            </div>
                        }
                    </Fragment>
                    :
                    <Fragment>
                        {ptList.length > 0 ?
                            <div>
                                <ListGroup style={{ margin: "0px 20px 0px 20px" }}>
                                    {ptList.map((PTs) => (
                                        <ListGroupItem className="venus-quote-list-item" key={PTs.id} action tag="button" onClick={() => { toggle(); getPOData(PTs.id) }}>
                                            <div>
                                                Project: {PTs.projectJobName}
                                            </div>
                                            <div>
                                                Project Tracker #: {PTs.ptNumber}
                                            </div>
                                        </ListGroupItem>
                                    ))}
                                </ListGroup>
                            </div>
                            :
                            <div className="venus-quote-no-quotes">
                                No Project Trackers to display
                            </div>
                        }
                    </Fragment>
                }
            </div>

            <Modal isOpen={modal} toggle={toggle} size="xl" backdrop="static" >
                <ModalHeader toggle={toggle}>

                </ModalHeader>

                <ModalBody style={{ padding: "0px 0px 0px 0px !important" }}>
                    <div id="venusQuote" ref={componentRef}>
                        <div className="venus-quote-modal-header">
                            <div style={{ display: "flex" }}>
                                <img alt="logo" src={logo} style={{ width: 75, height: 75 }} />
                                <p style={{ fontWeight: "bold", fontSize: "38px", marginTop: "10px" }}>Project Tracker</p>
                            </div>
                        </div>

                        <Table bordered size="sm">
                            <tbody>
                                <tr>
                                    <td style={{ width: "15%", textAlign: "right" }}>
                                        <strong>Project Name:</strong>
                                    </td>
                                    <td>
                                        <input name="projectJobName" value={ptData.projectJobName} style={{ width: "100%", textAlign: "left", border: "0"}} type="text" placeholder="Project name" spellCheck="false" autoComplete="off" maxLength="100" onChange={handlePOData} />
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ width: "15%", textAlign: "right" }}>
                                        <strong>PT #:</strong>
                                    </td>
                                    <td style={{textAlign: "left" }}>
                                        {ptData.ptNumber}
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                        
                        {"workItem" in ptData && Object.keys(ptData.workItem).length > 0 ?
                            <div>
                                <Table bordered>
                                    <thead>
                                        <tr>
                                            <th id="colItemNum" style={{ width: "5%", textAlign: "center" }}>
                                                Day
                                            </th>
                                            <th style={{ width: "10%", textAlign: "center" }}>
                                                Date
                                            </th>
                                            <th style={{ textAlign: "center" }}>
                                                Project Activities
                                            </th>
                                            <th style={{ width: "15%", textAlign: "center" }}>
                                                Engineer
                                            </th>
                                            {!printingPT ?
                                                <th style={{ width: "10%", textAlign: "center" }}>

                                                </th> : null
                                            }
                                        </tr>

                                    </thead>
                                    <tbody>
                                        {ptWorkItemsArray.map((workItemKey) => (
                                            <tr key={workItemKey}>
                                                <th scope="row" style={{ textAlign: "center", verticalAlign: "middle" }}>
                                                    {ptData.workItem[workItemKey][0]}
                                                </th>
                                                <th className="venus-quote-workitem" style={{ textAlign: "center", verticalAlign: "middle" }}>
                                                    <DatePicker className="venus-datepicker" selected={ptData.workItem[workItemKey][1] instanceof Timestamp ? new Date(ptData.workItem[workItemKey][1].seconds * 1000) : ptData.workItem[workItemKey][1]} onChange={(date) => handlePOData({ target: { name: "workItemDate_" + ptData.workItem[workItemKey][0], value: date }})} />
                                                </th>
                                                <td className="venus-quote-workitem">
                                                    <TextareaAutosize id="taWorkitemDescription" name={"workItemDescription_" + ptData.workItem[workItemKey][0]} className="venus-quote-workitem-input" value={ptData.workItem[workItemKey][2]} spellCheck="true" autoComplete="off" cacheMeasurements={true} onChange={handlePOData} />
                                                </td>
                                                <td className="venus-quote-workitem" style={{ textAlign: "center", verticalAlign: "middle" }}>
                                                    <TextareaAutosize name={"workItemEngineer_" + ptData.workItem[workItemKey][0]} value={ptData.workItem[workItemKey][3]} type="text" placeholder="Engineer" style={{ width: "100%", textAlign: "center", border: "0", verticalAlign: "middle", overflowWrap: "break-word" }} cacheMeasurements={true} spellCheck="false" autoComplete="off" onChange={handlePOData} />
                                                </td>
                                                {!printingPT ?
                                                    <td style={{ textAlign: "center", verticalAlign: "middle" }}>
                                                        <Button id={"btnDelete_" + ptData.workItem[workItemKey][0]} color="warning" style={{ margin: "20px 20px" }} onClick={handleDeleteWorkItem}>
                                                            <FontAwesomeIcon icon={faTrashCan} size={"lg"} />
                                                        </Button>
                                                    </td> : null
                                                }
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                            :
                            <div className="venus-quote-no-quotes">
                                No work days have been added yet
                            </div>
                        }
                    </div>
                </ModalBody>
                <Modal isOpen={nestedModal} toggle={toggleNested} size="sm">
                    <ModalBody style={{ textAlign: "center", fontWeight: "bold" }}>Changes successfully saved</ModalBody>
                    <ModalFooter style={{ display: "flex", justifyContent: "space-evenly" }}>
                        <Button color="success" onClick={toggleNested}>
                            <FontAwesomeIcon icon={faCircleCheck} size={"sm"} />
                        </Button>
                    </ModalFooter>
                </Modal>
                <ModalFooter style={{ display: "flex", justifyContent: "space-between" }}>
                    <Button color="primary" style={{ fontWeight: "bold" }} onClick={handleAddWorkItem}>+ Add Work Day</Button>
                    <Button color="danger" style={{ fontWeight: "bold" }} onClick={toggle}>Close</Button>
                    <div>
                        <Button color="success" style={{ fontWeight: "bold", marginRight: "10px" }} onClick={handlePrint}>Print</Button>
                        <Button color="success" style={{ fontWeight: "bold" }} onClick={handleSaveChanges}>Save Changes</Button>
                    </div>
                </ModalFooter>
            </Modal>
        </Fragment>
    )
};

export default VenusPT;