// For Firebase JS SDK v7.20.0 and later, measurementId is optional
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";
import {
    getAuth,
    signInWithEmailAndPassword,
    signOut
} from "firebase/auth";


const firebaseConfig = {
    apiKey: "AIzaSyAoIJg8-g4zCLuTHiRnG4TQ2g3v5yYr6JU",
    authDomain: "avimosaorbit.com",
    databaseURL: "https://avimosa-orbit-production-rtdb.firebaseio.com/",
    projectId: "avimosa-orbit",
    storageBucket: "avimosa-orbit.appspot.com",
    messagingSenderId: "909227130380",
    appId: "1:909227130380:web:915673528088ffacfff829",
    measurementId: "G-711NMRD9PZ"
};

const logInWithEmailAndPassword = async (email, password) => {
    try {
        await signInWithEmailAndPassword(auth, email, password);
    } catch (err) {
        console.error(err);
        alert(err.message);
    }
};

const logout = () => {
    signOut(auth);
};

const firebaseApp = initializeApp(firebaseConfig)

const storage = getStorage(firebaseApp);
const rtdb = getDatabase(firebaseApp);
const db = getFirestore(firebaseApp)
const auth = getAuth(firebaseApp);
export { db, storage, auth, logInWithEmailAndPassword, logout };
