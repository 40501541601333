import React, { Fragment, useState, useEffect, useRef } from "react";
import { db } from "../../firebaseConfig";
import { collection, query, where, doc, getDocs, getDoc, updateDoc, addDoc, orderBy, Timestamp } from "firebase/firestore";
import { useReactToPrint } from 'react-to-print';
import {
    Button,
    Input,
    Label,
    ListGroup,
    ListGroupItem,
    Table,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faTrashCan,
    faCircleCheck
} from "@fortawesome/free-solid-svg-icons";
import TextareaAutosize from 'react-textarea-autosize';

import logo from "../../assets/aw-logo.png";

const getFormattedDate = (dateInput) => {
    let date = new Date(dateInput.seconds * 1000)

    var year = date.getFullYear();

    var month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;

    var day = date.getDate().toString();
    day = day.length > 1 ? day : '0' + day;

    return month + '/' + day + '/' + year;
};

const MercuryPowerSharing = (props) => {
    const { userID, access, admin } = props;

    const [reportList, setReportList] = useState([]);
    const [reportData, setReportData] = useState({});
    const [reportDataItem, setReportDataItem] = useState({})
    const [reportItemsArray, setReportItemsArray] = useState([]);
    const [clientInfo, setClientInfo] = useState([0]);
    const [newReportNumber, setNewReportNumber] = useState(0);
    const [updateReport, setUpdateReport] = useState(false);
    const [printingReport, setPrintingReport] = useState(false);

    const [modal, setModal] = useState(false);
    const [nestedModal, setNestedModal] = useState(false);
    const [openAccordion, setOpenAccordion] = useState("1");

    const componentRef = useRef(null);
    const promiseResolveRef = useRef(null);

    const toggle = () => setModal(!modal);
    const toggleNested = () => setNestedModal(!nestedModal);
    const toggleAccordion = (id) => {
        if (openAccordion === id) {
            setOpenAccordion();
        } else {
            setOpenAccordion(id);
        }
    }

    const getReportList = async () => {
        let reportList = [];
        let latestReportNumber = 0;
        let currentReportNumber = -1;

        const q = query(collection(db, "Mercury_Reports_PowerSharing_SinglePage"), where("employeeID", "==", userID), orderBy("reportNumber", "desc"));
        const docSnap = await getDocs(q);

        docSnap.forEach((report) => {
            reportList.push({ id: report.id, ...report.data() });

            currentReportNumber = Number(report.data().reportNumber.toString().split("_")[4])
            latestReportNumber = currentReportNumber > latestReportNumber ? currentReportNumber : latestReportNumber;
        });

        setNewReportNumber(latestReportNumber + 1);

        if (reportList.length > 0) {
            setReportList(reportList);
        };
    };

    const getReportData = async (reportID) => {
        console.log(reportID);

        setUpdateReport(true);

        handleClearReportItems();

        const docRef = doc(db, "Mercury_Reports_PowerSharing_SinglePage", reportID);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            let data = { id: docSnap.id, ...docSnap.data() };

            data.reportCreated = getFormattedDate(data.reportCreated);

            setReportData(data);
            //if (data.report.length > 0) {
            //    setReportDataItem(data.report);
            //}
        };
    };

    const handleReportData = (e) => {
        reportData[e.target.name] = e.target.value;

        setReportData({ ...reportData });
    };

    const handleReportDataItem = (e) => {
        let controlName = e.target.name;
        let controlValue = e.target.value;

        if (["compositeRemotePower", "numberOfWSP", "technologySharePercent"].includes(e.target.name)) {
            reportDataItem[controlName] = controlValue === "" ? 0 : controlValue.replace(/^0+/, '');

            if (controlName === "numberOfWSP") {
                reportDataItem.wspSharingOffset = 10 * Math.log10(reportDataItem.numberOfWSP) === Number.NEGATIVE_INFINITY ? 0 : (10 * Math.log10(reportDataItem.numberOfWSP)).toFixed(2);
            }
            if (controlName === "compositeRemotePower" || controlName === "numberOfWSP") {
                reportDataItem.powerPerWSP_dBm = (parseFloat(reportDataItem.compositeRemotePower) - parseFloat(reportDataItem.wspSharingOffset)).toFixed(2);
                reportDataItem.powerPerWSP_W = (10 ** (reportDataItem.powerPerWSP_dBm / 10) / 1000).toFixed(2);
            }
            if (controlName === "technologySharePercent") {
                reportDataItem.technologyShareOffset = 10 * Math.log10(reportDataItem.technologySharePercent * 0.01) === Number.NEGATIVE_INFINITY ? 0 : (10 * Math.log10(reportDataItem.technologySharePercent * 0.01)).toFixed(2);
            }
            if (reportDataItem.compositeRemotePower > 0 && reportDataItem.technologySharePercent > 0 && reportDataItem.numberOfWSP) {
                reportDataItem.powerPerResult = (parseFloat(reportDataItem.powerPerWSP_dBm) + parseFloat(reportDataItem.technologyShareOffset)).toFixed(2);
            }

        } else {
            reportDataItem[e.target.name] = e.target.value;
        }
        
        setReportDataItem({ ...reportDataItem });
    };

    const handleCreateReport = (e) => {
        setUpdateReport(false);

        // clear client info
        setClientInfo([0]);

        // quote object
        const blankReport = {
            projectID: "",
            projectJobName: "",
            projectCustomer: "",
            projectWSP: "",
            reportDate: getFormattedDate(Timestamp.fromDate(new Date())),
            reportNumber: "AW_PS_" + new Date().getFullYear().toString() + "_" + userID + "_" + newReportNumber.toString().padStart(4, "0"),
            reportRevision: 1,
            siteVenueID: "",
            remoteID: "",
            // configuration info
            frequencyBand: "",
            bandID: 0,
            //rfPath: "",
            technology: "",
            channelBandwidth: "",
            numberOfChannels: 0,
            dlEarfcn: 0,
            dlCenterFrequency: 0,
            dlStartFrequency: 0,
            dlEndFrequency: 0,
            ulEarfcn: 0,
            ulCenterFrequency: 0,
            ulStartFrequency: 0,
            ulEndFrequency: 0,
            nrArfcn: 0,
            nrCenterFrequency: 0,
            nrStartFrequency: 0,
            nrEndFrequency: 0,
            report: []
        }

        setReportDataItem({
            order: null,
            rfPath: "A",
            compositeRemotePower: 0,
            numberOfWSP: 0,
            wspSharingOffset: 0,
            powerPerWSP_dBm: 0,
            powerPerWSP_W: 0,
            technologySharePercent: 0,
            technologyShareOffset: 0,
            powerPerResult: 0
        })

        setReportData({ ...blankReport });
    };

    const handleSaveChanges = async (event) => {
        if (updateReport) {
            // use quote auto-id to update doc
            const docRef = doc(db, "Mercury_Reports_PowerSharing_SinglePage", reportData.id);

            // convert Date to Timestamp - Timestamp.fromDate(new Date())

            await updateDoc(docRef, {
                projectID: reportData.projectID,
                projectJobName: reportData.projectJobName,
                projectCustomer: reportData.projectCustomer,
                projectWSP: reportData.projectWSP,
                reportDate: reportData.reportDate,
                reportNumber: reportData.reportNumber,
                reportRevision: reportData.reportRevision,
                siteVenueID: reportData.siteVenueID,
                remoteID: reportData.remoteID,
                // configuration info
                frequencyBand: reportData.frequencyBand,
                bandID: reportData.bandID,
                //rfPath: reportData.rfPath,
                technology: reportData.technology,
                channelBandwidth: reportData.channelBandwidth,
                numberOfChannels: reportData.numberOfChannels,
                dlEarfcn: reportData.dlEarfcn,
                dlCenterFrequency: reportData.dlCenterFrequency,
                dlStartFrequency: reportData.dlStartFrequency,
                dlEndFrequency: reportData.dlEndFrequency,
                ulEarfcn: reportData.ulEarfcn,
                ulCenterFrequency: reportData.ulCenterFrequency,
                ulStartFrequency: reportData.dlStartFrequency,
                ulEndFrequency: reportData.ulEndFrequency,
                nrArfcn: reportData.nrArfcn,
                nrCenterFrequency: reportData.nrCenterFrequency,
                nrStartFrequency: reportData.nrStartFrequency,
                nrEndFrequency: reportData.nrEndFrequency,
                report: reportData.report
            });

        } else {
            // create doc and save info
            await addDoc(collection(db, "Mercury_Reports_PowerSharing_SinglePage"), {
                employeeID: userID,
                projectID: reportData.projectID,
                projectJobName: reportData.projectJobName,
                projectCustomer: reportData.projectCustomer,
                projectWSP: reportData.projectWSP,
                reportDate: reportData.reportDate,
                reportCreated: Timestamp.fromDate(new Date()),
                reportNumber: reportData.reportNumber,
                reportRevision: reportData.reportRevision,
                siteVenueID: reportData.siteVenueID,
                remoteID: reportData.remoteID,
                // configuration info
                frequencyBand: reportData.frequencyBand,
                bandID: reportData.bandID,
                //rfPath: reportData.rfPath,
                technology: reportData.technology,
                channelBandwidth: reportData.channelBandwidth,
                numberOfChannels: reportData.numberOfChannels,
                dlEarfcn: reportData.dlEarfcn,
                dlCenterFrequency: reportData.dlCenterFrequency,
                dlStartFrequency: reportData.dlStartFrequency,
                dlEndFrequency: reportData.dlEndFrequency,
                ulEarfcn: reportData.ulEarfcn,
                ulCenterFrequency: reportData.ulCenterFrequency,
                ulStartFrequency: reportData.dlStartFrequency,
                ulEndFrequency: reportData.ulEndFrequency,
                nrArfcn: reportData.nrArfcn,
                nrCenterFrequency: reportData.nrCenterFrequency,
                nrStartFrequency: reportData.nrStartFrequency,
                nrEndFrequency: reportData.nrEndFrequency,
                report: reportData.report
            }).then((docRef) => {
                reportData.id = docRef.id;
            }).catch((error) => {
                console.log(error);
            });

            setUpdateReport(true);

            getReportList();
        }

        toggleNested();
    };

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: "AW_PowerSharing_" + reportData.projectJobName,
        onBeforeGetContent: () => {
            return new Promise((resolve) => {
                promiseResolveRef.current = resolve;
                setPrintingReport(true);
            });
        },
        onAfterPrint: () => {
            promiseResolveRef.current = null;
            setPrintingReport(false);
        }
    });

    // Get Data from report data item object and append to report object
    const handleAddReportItem = (e) => {
        let max = 0;

        // set current max order
        if (reportData.report.length > 0) {
            // change to reduce
            max = Math.max(...reportData.report.map(o => o.order))
        }
        reportDataItem.order = max + 1;
        reportData.report.push(reportDataItem);

        setReportDataItem({ ...reportDataItem });
        setReportData({ ...reportData });
    }

    const handleClearReportItems = (e) => {
        setReportDataItem({
            order: null,
            rfPath: "A",
            compositeRemotePower: 0,
            numberOfWSP: 0,
            wspSharingOffset: 0,
            powerPerWSP_dBm: 0,
            powerPerWSP_W: 0,
            technologySharePercent: 0,
            technologyShareOffset: 0,
            powerPerResult: 0
        })
    }

    const handleDeleteReportItem = (event) => {
        //let keyDelete = event.currentTarget.id.split("_")[1];

        //delete reportData.reportItem["item_" + keyDelete];
        //let filtered = reportData.report.filter(function (item) { return item.order !== keyDelete; })


        // set new keys and assign order
        //for (const key of Object.keys(reportData.report)) {
        //    if (Number(key.order) > Number(keyDelete)) {
        //        let newKey = (Number(key.split("_")[1]) - 1)

        //        Object.defineProperty(reportData.workItem, newKey, Object.getOwnPropertyDescriptor(reportData.reportItem, key));

        //        delete reportData.reportItem[key];
        //        reportData.reportItem[newKey][0] = newKey.split("_")[1];
        //    }
        //}

        //orderWorkItems(quoteData.workItem);
        //updateQuoteTotal(quoteData.workItem);
        reportData.report.shift()
        setReportData({ ...reportData });
    }

    // get users lists of quotes
    useEffect(() => {
        if (admin) {
            //getQuoteListAllUsers()
        } else {
            getReportList();
        }
    }, []);

    // set printing state
    useEffect(() => {
        if (printingReport && promiseResolveRef.current) {
            // Resolves the Promise, letting `react-to-print` know that the DOM updates are completed
            promiseResolveRef.current();
        }
    }, [printingReport]);

    return (
        <Fragment>
            <div>
                <div className="app-header">
                    <div style={{ fontSize: "26px", fontWeight: "bold", marginTop: "0px" }}>
                        Power Sharing Report Builder
                    </div>
                    <div>
                        <Button color="primary" style={{ fontWeight: "bold" }} onClick={() => { toggle(); handleCreateReport() }}>+ Create New Report</Button>
                    </div>
                </div>
                {reportList.length > 0 ?
                    <div>
                        <ListGroup style={{ margin: "0px 20px 0px 20px" }}>
                            {reportList.map((reports) => (
                                <ListGroupItem className="" key={reports.id} action tag="button" onClick={() => { toggle(); getReportData(reports.id) }}>
                                    <div style={{ fontWeight: "bold" }}>
                                        Project: {reports.projectJobName}
                                    </div>
                                    <div style={{ fontWeight: "bold" }}>
                                        Report ID: {reports.reportNumber}
                                    </div>
                                </ListGroupItem>
                            ))}
                        </ListGroup>
                    </div>
                    :
                    <div className="venus-quote-no-quotes">
                        You have not created any reports
                    </div>
                }
            </div>

            <Modal isOpen={modal} toggle={toggle} style={{ minWidth: "1000px" }} size="lg" backdrop="static" >
                <ModalHeader toggle={toggle}>
                    <div>

                    </div>
                </ModalHeader>

                <ModalBody style={{ padding: "0px 0px 0px 0px !important" }}>
                    <div id="mercuryReport" ref={componentRef}>
                        <div className="venus-quote-modal-header">
                            <div style={{ display: "flex" }}>
                                <img alt="logo" src={logo} style={{ width: 75, height: 75 }} />
                                <p style={{ fontWeight: "bold", fontSize: "38px", marginTop: "10px" }}>Power Sharing Report</p>
                            </div>
                        </div>

                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div style={{ width: "100%" }}>
                                <Table className="mercury-report-info-table" bordered size="sm">
                                    <tbody>
                                        <tr style={{ cursor: "default" }}>
                                            <td style={{ textAlign: "right", width: "20%", fontWeight: "bold" }}>
                                                Date:
                                            </td>
                                            <td style={{ textAlign: "left" }}>
                                                {reportData.reportDate}
                                            </td>
                                        </tr>
                                        <tr style={{ cursor: "default" }}>
                                            <td style={{ textAlign: "right", fontWeight: "bold" }}>
                                                AW Report ID:
                                            </td>
                                            <td style={{ textAlign: "left" }}>
                                                {reportData.reportNumber}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ textAlign: "right", cursor: "default", fontWeight: "bold" }}>
                                                Revision #:
                                            </td>
                                            <td style={{ textAlign: "left" }}>
                                                <input name="reportRevision" style={{ border: "none", outline: "none" }} value={reportData.reportRevision} type="text" spellCheck="false" autoComplete="off" onChange={handleReportData} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ textAlign: "right", cursor: "default", fontWeight: "bold" }}>
                                                Project Name:
                                            </td>
                                            <td style={{ textAlign: "left" }}>
                                                {/*<TextareaAutosize name="projectJobName" value={reportData.projectJobName} style={{ width: "300px", resize: "none", border: "none", outline: "none", overflow: "hidden" }} type="text" spellCheck="false" autoComplete="off" onChange={handleReportData} maxRows={1} minRows={1} maxLength="30" />*/}
                                                <input name="projectJobName" style={{ border: "none", outline: "none", width: "400px" }} value={reportData.projectJobName} type="text" spellCheck="false" autoComplete="off" onChange={handleReportData} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ textAlign: "right", cursor: "default", fontWeight: "bold" }}>
                                                Customer:
                                            </td>
                                            <td style={{ textAlign: "left"}}>
                                                <input name="projectCustomer" style={{ border: "none", outline: "none", width: "400px" }} value={reportData.projectCustomer} type="text" spellCheck="false" autoComplete="off" onChange={handleReportData} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ textAlign: "right", cursor: "default", fontWeight: "bold" }}>
                                                WSP:
                                            </td>
                                            <td style={{ textAlign: "left" }}>
                                                <input name="projectWSP" style={{ border: "none", outline: "none", width: "400px" }} value={reportData.projectWSP} type="text" spellCheck="false" autoComplete="off" onChange={handleReportData} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ textAlign: "right", cursor: "default", fontWeight: "bold" }}>
                                                Site ID/Venue:
                                            </td>
                                            <td style={{ textAlign: "left" }}>
                                                <input name="siteVenueID" style={{ border: "none", outline: "none", width: "400px" }} value={reportData.siteVenueID} type="text" spellCheck="false" autoComplete="off" onChange={handleReportData} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ textAlign: "right", cursor: "default", fontWeight: "bold" }}>
                                                Remote ID:
                                            </td>
                                            <td style={{ textAlign: "left" }}>
                                                <input name="remoteID" style={{ border: "none", outline: "none", width: "400px" }} value={reportData.remoteID} type="text" spellCheck="false" autoComplete="off" onChange={handleReportData} />
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                        <div style={{ fontSize: "18px", fontWeight: "bold", marginTop: "0px", marginBottom: "5px" }}>
                            Configuration
                        </div>
                        <div className="mercury-report-input-containers">
                            <div className="mercury-report-input-controls">
                                <div className="mercury-report-input-labels">
                                    <div className="mercury-report-inputs">
                                        <div className="mercury-report-input-label">
                                            <p>Frequency Band:</p>
                                        </div>
                                        <Input name="frequencyBand" className="mercury-report-input-dropdown" type="select" onChange={(e) => handleReportData(e)} >
                                            <option>
                                                700
                                            </option>
                                            <option>
                                                850
                                            </option>
                                            <option>
                                                1900
                                            </option>
                                            <option>
                                                2100
                                            </option>
                                            <option>
                                                2300
                                            </option>
                                            <option>
                                                2500
                                            </option>
                                            <option>
                                                n75
                                            </option>
                                            <option>
                                                n77
                                            </option>
                                            <option>
                                                NA
                                            </option>
                                        </Input>
                                        <div className="mercury-report-input-label-units">
                                            (MHz)
                                        </div>
                                    </div>
                                    <div className="mercury-report-inputs">
                                        <div className="mercury-report-input-label">
                                            <p>Band ID:</p>
                                        </div>
                                        <input className="mercury-report-input-number" name="bandID" value={reportData.bandID} type="number" spellCheck="false" autoComplete="off" onChange={(e) => handleReportData(e)} />
                                        <div className="mercury-report-input-label-units">
                                        </div>
                                    </div>
                                    {/*<div className="mercury-report-inputs">*/}
                                    {/*    <div className="mercury-report-input-label">*/}
                                    {/*        <p>RF Path:</p>*/}
                                    {/*    </div>*/}
                                        {/*<Input name="rfPath" className="mercury-report-input-dropdown" type="select" onChange={(e) => handleReportData(e)} >*/}
                                        {/*    <option>*/}
                                        {/*        A*/}
                                        {/*    </option>*/}
                                        {/*    <option>*/}
                                        {/*        B*/}
                                        {/*    </option>*/}
                                        {/*    <option>*/}
                                        {/*        NA*/}
                                        {/*    </option>*/}
                                        {/*</Input>*/}
                                    {/*    <div className="mercury-report-input-label-units">*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                            <div className="mercury-report-input-controls">
                                <div className="mercury-report-input-labels">
                                    <div className="mercury-report-inputs">
                                        <div className="mercury-report-input-label">
                                            <p>Technology:</p>
                                        </div>
                                        <Input name="technology" className="mercury-report-input-dropdown" type="select" onChange={(e) => handleReportData(e)} >
                                            <option>
                                                LTE
                                            </option>
                                            <option>
                                                5G NR
                                            </option>
                                            <option>
                                                UMTS
                                            </option>
                                            <option>
                                                1sNET
                                            </option>
                                            <option>
                                                NA
                                            </option>
                                        </Input>
                                        <div className="mercury-report-input-label-units">
                                        </div>
                                    </div>
                                    <div className="mercury-report-inputs">
                                        <div className="mercury-report-input-label">
                                            <p>Channel Bandwidth:</p>
                                        </div>
                                        <Input name="channelBandwidth" className="mercury-report-input-dropdown" type="select" onChange={(e) => handleReportData(e)} >
                                            <option>
                                                2.5
                                            </option>
                                            <option>
                                                5
                                            </option>
                                            <option>
                                                10
                                            </option>
                                            <option>
                                                15
                                            </option>
                                            <option>
                                                20
                                            </option>
                                            <option>
                                                25
                                            </option>
                                            <option>
                                                30
                                            </option>
                                            <option>
                                                35
                                            </option>
                                            <option>
                                                40
                                            </option>
                                            <option>
                                                100
                                            </option>
                                            <option>
                                                140
                                            </option>
                                            <option>
                                                NA
                                            </option>
                                        </Input>
                                        <div className="mercury-report-input-label-units">
                                            (MHz)
                                        </div>
                                    </div>
                                    <div className="mercury-report-inputs">
                                        <div className="mercury-report-input-label">
                                            <p>Number of Channels:</p>
                                        </div>
                                        <input name="numberOfChannels" className="mercury-report-input-number" value={reportData.numberOfChannels} type="number" spellCheck="false" autoComplete="off" onChange={(e) => handleReportData(e)} />
                                        <div className="mercury-report-input-label-units">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br/>
                        <div className="mercury-report-input-containers">
                            <div className="mercury-report-input-controls">
                                <div className="mercury-report-input-labels">
                                    <div className="mercury-report-inputs">
                                        <div className="mercury-report-input-label">
                                            <p>DL Earfcn:</p>
                                        </div>
                                        <input name="dlEarfcn" className="mercury-report-input-number" value={reportData.dlEarfcn} type="number" spellCheck="false" autoComplete="off" onChange={(e) => handleReportData(e)} />
                                        <div className="mercury-report-input-label-units">
                                        </div>
                                    </div>
                                    <div className="mercury-report-inputs">
                                        <div className="mercury-report-input-label">
                                            <p>DL Center Frequency:</p>
                                        </div>
                                        <input name="dlCenterFrequency" className="mercury-report-input-number" value={reportData.dlCenterFrequency} type="number" spellCheck="false" autoComplete="off" onChange={(e) => handleReportData(e)} />
                                        <div className="mercury-report-input-label-units">
                                            (MHz)
                                        </div>
                                    </div>
                                    <div className="mercury-report-inputs">
                                        <div className="mercury-report-input-label">
                                            <p>UL Earfcn:</p>
                                        </div>
                                        <input name="ulEarfcn" className="mercury-report-input-number" value={reportData.ulEarfcn} type="number" spellCheck="false" autoComplete="off" onChange={(e) => handleReportData(e)} />
                                        <div className="mercury-report-input-label-units">
                                        </div>
                                    </div>
                                    <div className="mercury-report-inputs">
                                        <div className="mercury-report-input-label">
                                            <p>UL Center Frequency:</p>
                                        </div>
                                        <input name="ulCenterFrequency" className="mercury-report-input-number" value={reportData.ulCenterFrequency} type="number" spellCheck="false" autoComplete="off" onChange={(e) => handleReportData(e)} />
                                        <div className="mercury-report-input-label-units">
                                            (MHz)
                                        </div>
                                    </div>
                                    <div className="mercury-report-inputs">
                                        <div className="mercury-report-input-label">
                                            <p>nr-arfcn:</p>
                                        </div>
                                        <input name="nrArfcn" className="mercury-report-input-number" value={reportData.nrArfcn} type="number" spellCheck="false" autoComplete="off" onChange={(e) => handleReportData(e)} />
                                        <div className="mercury-report-input-label-units">
                                        </div>
                                    </div>
                                    <div className="mercury-report-inputs">
                                        <div className="mercury-report-input-label">
                                            <p>nr-Center Frequency:</p>
                                        </div>
                                        <input name="nrCenterFrequency" className="mercury-report-input-number" value={reportData.nrCenterFrequency} type="number" spellCheck="false" autoComplete="off" onChange={(e) => handleReportData(e)} />
                                        <div className="mercury-report-input-label-units">
                                            (MHz)
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mercury-report-input-controls">
                                <div className="mercury-report-input-labels">
                                    <div className="mercury-report-inputs">
                                        <div className="mercury-report-input-label">
                                            <p>DL Start Frequency:</p>
                                        </div>
                                        <input name="dlStartFrequency" className="mercury-report-input-number" value={reportData.dlStartFrequency} type="number" spellCheck="false" autoComplete="off" onChange={(e) => handleReportData(e)} />
                                        <div className="mercury-report-input-label-units">
                                            (MHz)
                                        </div>
                                    </div>
                                    <div className="mercury-report-inputs">
                                        <div className="mercury-report-input-label">
                                            <p>DL End Frequency:</p>
                                        </div>
                                        <input name="dlEndFrequency" className="mercury-report-input-number" value={reportData.dlEndFrequency} type="number" spellCheck="false" autoComplete="off" onChange={(e) => handleReportData(e)} />
                                        <div className="mercury-report-input-label-units">
                                            (MHz)
                                        </div>
                                    </div>
                                    <div className="mercury-report-inputs">
                                        <div className="mercury-report-input-label">
                                            <p>UL Start Frequency:</p>
                                        </div>
                                        <input name="ulStartFrequency" className="mercury-report-input-number" value={reportData.ulStartFrequency} type="number" spellCheck="false" autoComplete="off" onChange={(e) => handleReportData(e)} />
                                        <div className="mercury-report-input-label-units">
                                            (MHz)
                                        </div>
                                    </div>
                                    <div className="mercury-report-inputs">
                                        <div className="mercury-report-input-label">
                                            <p>UL End Frequency:</p>
                                        </div>
                                        <input name="ulEndFrequency" className="mercury-report-input-number" value={reportData.ulEndFrequency} type="number" spellCheck="false" autoComplete="off" onChange={(e) => handleReportData(e)} />
                                        <div className="mercury-report-input-label-units">
                                            (MHz)
                                        </div>
                                    </div>
                                    <div className="mercury-report-inputs">
                                        <div className="mercury-report-input-label">
                                            <p>nr-Start Frequency:</p>
                                        </div>
                                        <input name="nrStartFrequency" className="mercury-report-input-number" value={reportData.nrStartFrequency} type="number" spellCheck="false" autoComplete="off" onChange={(e) => handleReportData(e)} />
                                        <div className="mercury-report-input-label-units">
                                            (MHz)
                                        </div>
                                    </div>
                                    <div className="mercury-report-inputs">
                                        <div className="mercury-report-input-label">
                                            <p>nr-End Frequency:</p>
                                        </div>
                                        <input name="nrEndFrequency" className="mercury-report-input-number" value={reportData.nrEndFrequency} type="number" spellCheck="false" autoComplete="off" onChange={(e) => handleReportData(e)} />
                                        <div className="mercury-report-input-label-units">
                                            (MHz)
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {!printingReport ?
                            <div className="mercury-report-inputs-accordion">
                                <Accordion open={openAccordion} toggle={toggleAccordion}>
                                    <AccordionItem>
                                        <AccordionHeader targetId="1">
                                            Calculation Inputs
                                        </AccordionHeader>
                                        <AccordionBody accordionId="1">
                                            <div className="mercury-report-input-containers" style={{ marginTop: "20px", marginBottom: "20px" }}>
                                                <div className="mercury-report-input-controls">
                                                    <div className="mercury-report-input-labels">

                                                        <div className="mercury-report-inputs">
                                                            <div className="mercury-report-input-label">
                                                                <p>RF Path:</p>
                                                            </div>
                                                            <Input name="rfPath" value={reportDataItem.rfPath} className="mercury-report-input-dropdown" type="select" onChange={(e) => handleReportDataItem(e)} >
                                                                <option>
                                                                    A
                                                                </option>
                                                                <option>
                                                                    B
                                                                </option>
                                                                <option>
                                                                    NA
                                                                </option>
                                                            </Input>
                                                            <div className="mercury-report-input-label-units">
                                                                
                                                            </div>
                                                        </div>

                                                        <div className="mercury-report-inputs">
                                                            <div className="mercury-report-input-label">
                                                                <p>Composite Remote Power:</p>
                                                            </div>
                                                            <input className="mercury-report-input-number" name="compositeRemotePower" value={reportDataItem.compositeRemotePower} type="number" spellCheck="false" autoComplete="off" onChange={(e) => handleReportDataItem(e)} />
                                                            <div className="mercury-report-input-label-units">
                                                                (dBm)
                                                            </div>
                                                        </div>
                                                        <div className="mercury-report-inputs">
                                                            <div className="mercury-report-input-label">
                                                                <p># of WSP Sharing:</p>
                                                            </div>
                                                            <input className="mercury-report-input-number" name="numberOfWSP" value={reportDataItem.numberOfWSP} type="number" spellCheck="false" autoComplete="off" onChange={(e) => handleReportDataItem(e)} />
                                                            <div className="mercury-report-input-label-units">
                                                            </div>
                                                        </div>
                                                        <div className="mercury-report-inputs">
                                                            <div className="mercury-report-input-label">
                                                                <p>WSP Sharing Offset:</p>
                                                            </div>
                                                            <input className="mercury-report-input-number" style={{ background: "lightgray" }} name="wspSharingOffset" readOnly="true" value={reportDataItem.wspSharingOffset} type="number" spellCheck="false" autoComplete="off" onChange={(e) => handleReportDataItem(e)} />

                                                            <div className="mercury-report-input-label-units">
                                                                (dB)
                                                            </div>
                                                        </div>
                                                        <div className="mercury-report-inputs">
                                                            <div className="mercury-report-input-label">
                                                                <p>Power per WSP:</p>
                                                            </div>
                                                            <input className="mercury-report-input-number" style={{ background: "lightgray" }} name="powerPerWSP_dBm" readOnly="true" value={reportDataItem.powerPerWSP_dBm} type="number" spellCheck="false" autoComplete="off" onChange={(e) => handleReportDataItem(e)} />
                                                            <div className="mercury-report-input-label-units">
                                                                (dBm)
                                                            </div>
                                                        </div>
                                                        <div className="mercury-report-inputs">
                                                            <div className="mercury-report-input-label">
                                                                <p>Power per WSP:</p>
                                                            </div>
                                                            <input className="mercury-report-input-number" style={{ background: "lightgray" }} name="powerPerWSP_W" readOnly="true" value={reportDataItem.powerPerWSP_W} type="number" spellCheck="false" autoComplete="off" onChange={(e) => handleReportDataItem(e)} />
                                                            <div className="mercury-report-input-label-units">
                                                                (W)
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mercury-report-input-controls" style={{ marginTop: "0px" }}>
                                                    <div className="mercury-report-input-labels" style={{ marginTop: "20px" }}>
                                                        <div className="mercury-report-inputs">
                                                            <div className="mercury-report-input-label">
                                                                <p>Technology Share Percentage:</p>
                                                            </div>
                                                            <input name="technologySharePercent" className="mercury-report-input-number" value={reportDataItem.technologySharePercent} type="number" spellCheck="false" autoComplete="off" onChange={(e) => handleReportDataItem(e)} />
                                                            <div className="mercury-report-input-label-units">
                                                                %
                                                            </div>
                                                        </div>
                                                        <div className="mercury-report-inputs" style={{ marginTop: "20px" }}>
                                                            <div className="mercury-report-input-label">
                                                                <p>Technology Share Offset:</p>
                                                            </div>
                                                            <input name="technologyShareOffset" className="mercury-report-input-number" style={{ background: "lightgray" }} readOnly="true" value={reportDataItem.technologyShareOffset} type="number" spellCheck="false" autoComplete="off" onChange={(e) => handleReportDataItem(e)} />
                                                            <div className="mercury-report-input-label-units">
                                                                (dB)
                                                            </div>
                                                        </div>
                                                        <div className="mercury-report-inputs" style={{ marginTop: "20px" }}>
                                                            <div className="mercury-report-input-label">
                                                                <p>Power per WSP/Band/Tech from Remote:</p>
                                                            </div>
                                                            <input name="powerPerResult" className="mercury-report-input-number" style={{ background: "lightgray" }} readOnly="true" value={reportDataItem.powerPerResult} type="number" spellCheck="false" autoComplete="off" onChange={(e) => handleReportDataItem(e)} />
                                                            <div className="mercury-report-input-label-units">
                                                                (dBm)
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <Button color="warning" style={{ fontWeight: "bold", marginRight: "10px" }} onClick={handleClearReportItems}>Clear Inputs</Button>
                                                {"report" in reportData && Object.keys(reportData.report).length <= 1 ?
                                                    <Button color="primary" style={{ fontWeight: "bold" }} onClick={handleAddReportItem}>+ Add Calculation</Button>
                                                    : null
                                                }
                                            </div>
                                        </AccordionBody>
                                    </AccordionItem>
                                </Accordion>
                            </div> : null
                        }
                        <div style={{ fontSize: "18px", fontWeight: "bold", marginTop: "0px", marginBottom: "5px" }}>
                            Results
                        </div>
                        {/* ADD - ability to reorganize rows */}
                        {"report" in reportData && Object.keys(reportData.report).length > 0 ?
                            <div>
                                <Table bordered>
                                    <thead>
                                        <tr>
                                            {/*<th id="colItemNum" style={{ width: "10%" }} className="mercury-report-result-table-header">*/}
                                            {/*    #*/}
                                            {/*</th>*/}
                                            <th className="mercury-report-result-table-header" style={{ maxWidth: "75px" }}>
                                                RF Path
                                            </th>
                                            <th className="mercury-report-result-table-header">
                                                Composite Remote Power (dBm)
                                            </th>
                                            <th className="mercury-report-result-table-header">
                                                # of WSPs sharing
                                            </th>
                                            <th className="mercury-report-result-table-header">
                                                WSP Sharing Offset (dB)
                                            </th>
                                            <th className="mercury-report-result-table-header">
                                                Power per WSP (dBm)
                                            </th>
                                            <th className="mercury-report-result-table-header">
                                                Power per WSP (W)
                                            </th>
                                            <th className="mercury-report-result-table-header">
                                                Technology Share %
                                            </th>
                                            <th className="mercury-report-result-table-header">
                                                Technology Share Offset (dB) 
                                            </th>
                                            <th className="mercury-report-result-table-header">
                                                Power per WSP/Band/Tech from Remote (dBm)
                                            </th>
                                            {!printingReport ?
                                                <th style={{ width: "10%", textAlign: "center" }}>

                                                </th> : null
                                            }
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {reportData.report.map((reportItem) => (
                                            <tr key={reportItem.order}>
                                                {/*<th scope="row" className="mercury-report-result-table-header">*/}
                                                {/*    {reportItem.order}*/}
                                                {/*</th>*/}
                                                <td className="mercury-report-result-table-item">
                                                    {reportItem.rfPath}
                                                </td>
                                                <td className="mercury-report-result-table-item">
                                                    {reportItem.compositeRemotePower}
                                                </td>
                                                <td className="mercury-report-result-table-item">
                                                    {reportItem.numberOfWSP}
                                                </td>
                                                <td className="mercury-report-result-table-item">
                                                    {reportItem.wspSharingOffset}
                                                </td>
                                                <td className="mercury-report-result-table-item">
                                                    {reportItem.powerPerWSP_dBm}
                                                </td>
                                                <td className="mercury-report-result-table-item">
                                                    {reportItem.powerPerWSP_W}
                                                </td>
                                                <td className="mercury-report-result-table-item">
                                                    {reportItem.technologySharePercent}
                                                </td>
                                                <td className="mercury-report-result-table-item">
                                                    {reportItem.technologyShareOffset}
                                                </td>
                                                <td className="mercury-report-result-table-item">
                                                    {reportItem.powerPerResult}
                                                </td>
                                                {!printingReport ?
                                                    <td style={{ textAlign: "center", verticalAlign: "middle" }}>
                                                        <Button id={"btnDelete_" + reportItem.order} color="warning" style={{ margin: "20px 20px" }} onClick={handleDeleteReportItem}>
                                                            <FontAwesomeIcon icon={faTrashCan} size={"lg"} />
                                                        </Button>
                                                    </td> : null
                                                }
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                            :
                            <div className="venus-quote-no-quotes">
                                No calculations have been added yet
                            </div>
                        }
                    </div>
                </ModalBody>
                <Modal isOpen={nestedModal} toggle={toggleNested} size="sm">
                    <ModalBody style={{ textAlign: "center", fontWeight: "bold" }}>Changes successfully saved</ModalBody>
                    <ModalFooter style={{ display: "flex", justifyContent: "space-evenly" }}>
                        <Button color="success" onClick={toggleNested}>
                            <FontAwesomeIcon icon={faCircleCheck} size={"sm"} />
                        </Button>
                    </ModalFooter>
                </Modal>
                <ModalFooter style={{ display: "flex", justifyContent: "space-between" }}>
                    <Button color="primary" style={{ fontWeight: "bold" }} onClick={handleAddReportItem}>+ Add Report Item</Button>
                    <Button color="danger" style={{ fontWeight: "bold" }} onClick={toggle}>Close</Button>
                    <div>
                        <Button color="success" style={{ fontWeight: "bold", marginRight: "10px" }} onClick={handlePrint}>Print</Button>
                        <Button color="success" style={{ fontWeight: "bold" }} onClick={handleSaveChanges}>Save Changes</Button>
                    </div>
                </ModalFooter>
            </Modal>
        </Fragment>
    )
}

export default MercuryPowerSharing;